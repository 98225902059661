import React, { useContext, useEffect } from 'react'
import { MainContext } from '../../../context/mainContext'

export const Popup = () => {
	const ctx = useContext(MainContext)

    useEffect(() => {
        const close = (e) => {
          if(e.keyCode === 27){
            ctx.closePopup()
          }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    },[])

    if (ctx.popup) {
        switch (ctx.popup.class) {
            case 'small':
                return(
                    <>
                        <div className={(ctx.popup.catalogType && ctx.popup.catalogType!='')  ? "modal show verificationPopup" :"modal show"} style={{display: "block"}} data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">                                    
                                    <div className="modal-header">
                                    {(ctx.popup.catalogType && ctx.popup.catalogType!='')  ? "" : <h5 className="modal-title">{ctx.popup.title}</h5>}
                                    <button onClick={() => ctx.closePopup()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span className="fa-stack">
                                            <i className="fal fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-times fa-stack-1x"></i>
                                        </span>
                                    </button>
                                    </div>
                                    <div className={(ctx.popup.catalogType && ctx.popup.catalogType!='')  ? "modal-body capnGownPopup" :"modal-body"}>
                                        <div margin="20px 0 0 0">
                                            { ctx.popup.content }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop show"></div>
                    </>
                )
                break;
                case 'fullscreen':
                    return(
                        <>
                            <div className="modal fullscreen-modal show" id="events-modal" style={{display: "block", paddingRight: '19px'}} data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button onClick={() => ctx.closePopup()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span className="fa-stack">
                                                    <i className="fal fa-circle fa-stack-2x"></i>
                                                    <i className="fas fa-times fa-stack-1x"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            { ctx.popup.content }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop show"></div>
                        </>
                    )
                    break;
            
            default:
                return null
                break;
        }
    } else {
        return null
    }
}