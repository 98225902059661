import React, { useEffect, useCallback, useState, useRef } from 'react'

const PromoMessage = ({ promoMessage, onClose, idx }) => {

    const refContainer = useRef(false)
    const refContent = useRef(false)
    const [isOver, setIsOver] = useState(false)
    const [expandable, setExpandable] = useState(false)

    useEffect(() => {
        if (refContainer.current && refContent.current ) {
            const rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
            setIsOver(refContent.current.clientHeight > 3*rem)
            setExpandable(refContent.current.clientHeight > 3*rem)
        }
    }, [])

    const colStyle = {
        overflow: 'hidden',
        display:'-webkit-box',
        '-webkit-line-clamp':'2',
        '-webkit-box-orient':'vertical'
    }

    const expStyle = {
        overflow: 'auto',
        display: 'block',
        '-webkit-line-clamp': 'none',
        '-webkit-box-orient': 'none'
    }

    const seeMoreContainerStyle = {
        width:'100%',
        textAlign: 'right',
    }

    const moreClick = () => {
        setIsOver(false)
    }

    const lessClick = () => {
        setIsOver(true)
    }

    return (
            <div ref={refContainer} className='alert alert-dismissible fade show' role="alert">
                <button type="button" onClick={() => onClose(idx)} className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                {promoMessage.messageTitle && <strong>{promoMessage.messageTitle}</strong>}
                <div ref={refContent} style={ isOver ? colStyle : expStyle } dangerouslySetInnerHTML={{__html: promoMessage.messageDesc}}/>
                {expandable ? isOver ? <div style={seeMoreContainerStyle}><a href='#' onClick={moreClick}>See more</a></div> : <div style={seeMoreContainerStyle}><a href='#' onClick={lessClick}>See less</a></div> : null}
            </div>        
        ) 
}

export const PromoMessages = ({ appData:{storedata} }) => {
    const [messages, setMessages] = useState(storedata?.schoolStorePromoMessagesV2?.filter(m => m.messageDesc && !/^\s*$/.test(m.messageDesc)))
    const onClose = useCallback((idx) => {
        setMessages(messages.filter((_, i) => i !== idx))
    }, [messages])


    if (messages?.length > 0) {
        return <div className="announcements">
                    { messages.map((promoMessage, key) => <PromoMessage idx={key} onClose={onClose} key={`PromoMessage-${key}`} promoMessage={promoMessage} />)}
                </div>
    } else {
        return null
    }
}

export default PromoMessages