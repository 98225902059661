import React, { useCallback } from 'react'
// import { useInView } from 'react-intersection-observer'
import { m, LazyMotion, domAnimation } from "framer-motion"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { domainConverter, actionUrlConverter } from '../../util/util'

export const TopSellers = React.memo(( { appData:{storedata} }) => {

    /* const [ref, inView] = useInView({
        threshold: 0,
    }) */

	let settings = {
        accessibility: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        dots: false,
        variableWidth: false,
        adaptiveHeight: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                    variableWidth: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                    arrows: false,
                    variableWidth: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false,
                    arrows: true,
                    variableWidth: false
                }
            }
        ]
    }

    const container = {
        show: {
          transition: {
            staggerChildren: 0.2
          }
        }
    }

    const product = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                duration: 0.25
            }
        }
    }

    const price = useCallback(
        (price, code) => {
            if (code) {
                switch (code) {
                    case "USD":
                        return `$${price.replace(/\s/g, '')}`

                    case "CAD":
                        return `CAD $${price.replace(/\s/g, '')}`

                    case "EUR":
                        return `EUR ${price.replace(/\s/g, '')}`

                    default:
                        return `$${price.replace(/\s/g, '')}`
                }
            }
        },
        [],
    )

    if (storedata && storedata?.productTopSellers && storedata?.productTopSellers?.length) {
        return (
            <LazyMotion features={domAnimation}>
                <div
                    className="top-sellers"
                    // ref={ref}
                >
                    <m.div
                        className="container"
                        variants={container}
                        initial="hidden"
                        animate="show"
                    >
                        <h2>SCHOOL TOP SELLERS</h2>
                        <Slider {...settings} className="carousel">
                            { storedata.productTopSellers.map((topseller, key) =>
                                <m.div
                                    key={`TopSellerItem-${key}`}
                                    className="product"
                                    variants={product}
                                >
                                    <div className="inner">
                                        <div>
                                            { !topseller.retailPricePreMessage && !topseller.retailPrice &&
                                                <div className="price">{price(topseller.formattedPrice, topseller.currencyCode)}</div>
                                            }
                                            { topseller.retailPricePreMessage && !topseller.retailPrice &&
                                                <div className="price">{topseller.retailPricePreMessage}</div>
                                            }
                                            { !topseller.retailPricePreMessage && topseller.retailPrice &&
                                                <div className="price"><span style={{textDecoration:'line-through'}}>{price(topseller.retailPrice, topseller.currencyCode)}</span> <span style={{color:'red'}}>{price(topseller.formattedPrice, topseller.currencyCode)}</span></div>
                                            }
                                            { topseller.retailPricePreMessage && topseller.retailPrice &&
                                                <div className="price">{topseller.retailPricePreMessage} <span style={{textDecoration:'line-through'}}>{price(topseller.formattedPrice, topseller.currencyCode)}</span></div>
                                            }
                                            <h3>{topseller.displayName}</h3>
                                            <div className="img">
                                                <img src={domainConverter(topseller.imageUrl)} alt={topseller.displayName} loading="lazy" />
                                            </div>
                                        </div>
                                        <div>
                                            <a href={actionUrlConverter(topseller.actionUrl)} className="btn btn-primary btn-primary--ghost">START NOW</a>
                                        </div>
                                    </div>
                                </m.div>)
                            }
                        </Slider>
                    </m.div>
                </div>
            </LazyMotion>
        )
    } else {
        return null
    }
})

// export default React.memo(TopSellers)