import React, { Fragment, useContext } from 'react'
import styled from 'styled-components/macro'
import { MainContext } from "../../../context/mainContext";

const Background = styled.section`
	height: 100%;
	width: 100%;
	position: fixed;
	background: rgba(0,0,0,.5);
	top: 0;
	left: 0;
	z-index: 14;
	display: flex;
    align-items: center;
    justify-content: center;
`

export const Spinner = styled.div`
	display: inline-block;
	width: ${props => props.size ? props.size : "100px"};
	height: ${props => props.size ? props.size : "100px"};
	border: ${props => props.thickness ? props.thickness : "10px"} solid rgba(255,255,255,.3);
	border-radius: 50%;
	border-top-color: #fff;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	@keyframes spin {
      to { -webkit-transform: rotate(360deg); }
	}
	@-webkit-keyframes spin {
      to { -webkit-transform: rotate(360deg); }
	}
`

const Success = styled.div`
		width: 80px;
		height: 115px;
		margin: 0 auto;

		.check-icon {
			width: 80px;
			height: 80px;
			position: relative;
			border-radius: 50%;
			box-sizing: content-box;
			border: 4px solid #4CAF50;

			&::before {
				top: 3px;
				left: -2px;
				width: 30px;
				transform-origin: 100% 50%;
				border-radius: 100px 0 0 100px;
			}

			&::after {
				top: 0;
				left: 30px;
				width: 60px;
				transform-origin: 0 50%;
				border-radius: 0 100px 100px 0;
				animation: rotate-circle 4.25s ease-in;
			}

			&::before, &::after {
				content: '';
				height: 100px;
				position: absolute;
				background: #none;
				transform: rotate(-45deg);
			}

			.icon-line {
				height: 5px;
				background-color: #4CAF50;
				display: block;
				border-radius: 2px;
				position: absolute;
				z-index: 10;

				&.line-tip {
					top: 46px;
					left: 14px;
					width: 25px;
					transform: rotate(45deg);
					animation: icon-line-tip 0.75s;
				}

				&.line-long {
					top: 38px;
					right: 8px;
					width: 47px;
					transform: rotate(-45deg);
					animation: icon-line-long 0.75s;
				}
			}

			.icon-circle {
				top: -4px;
				left: -4px;
				z-index: 10;
				width: 80px;
				height: 80px;
				border-radius: 50%;
				position: absolute;
				box-sizing: content-box;
				border: 4px solid rgba(76, 175, 80, .5);
			}

			.icon-fix {
				top: 8px;
				width: 5px;
				left: 26px;
				z-index: 1;
				height: 85px;
				position: absolute;
				transform: rotate(-45deg);
				background-color: none;
			}
		}
	}

	@keyframes rotate-circle {
		0% {
			transform: rotate(-45deg);
		}
		5% {
			transform: rotate(-45deg);
		}
		12% {
			transform: rotate(-405deg);
		}
		100% {
			transform: rotate(-405deg);
		}
	}

	@keyframes icon-line-tip {
		0% {
			width: 0;
			left: 1px;
			top: 19px;
		}
		54% {
			width: 0;
			left: 1px;
			top: 19px;
		}
		70% {
			width: 50px;
			left: -8px;
			top: 37px;
		}
		84% {
			width: 17px;
			left: 21px;
			top: 48px;
		}
		100% {
			width: 25px;
			left: 14px;
			top: 45px;
		}
	}

	@keyframes icon-line-long {
		0% {
			width: 0;
			right: 46px;
			top: 54px;
		}
		65% {
			width: 0;
			right: 46px;
			top: 54px;
		}
		84% {
			width: 55px;
			right: 0px;
			top: 35px;
		}
		100% {
			width: 47px;
			right: 8px;
			top: 38px;
	}
`

const Loader = () => {
	const ctx = useContext(MainContext)

	return(
		<Fragment>
			{ctx.isLoading
				? <Background>
					<Spinner />
				</Background>
				: '' }
			{ctx.isSuccess
				? <Background>
					<Success>
						<div className="check-icon">
							<span className="icon-line line-tip"></span>
							<span className="icon-line line-long"></span>
							<div className="icon-circle"></div>
							<div className="icon-fix"></div>
						</div>
					</Success>
				</Background>
				: '' }
		</Fragment>
	)
}

export default Loader
