export const gaPageLoad  = () => {
  let extendedName = "";
  if (window?.affiliationData?.name && window?.affiliationData?.city && window?.affiliationData?.state) {
    extendedName = window?.affiliationData?.name + "(" + window?.affiliationData?.city +"," + window?.affiliationData?.state +")";
  } else if(window?.affiliationData?.name) {
    extendedName = window?.affiliationData?.name
  }

  window && !window.dataLayer && (window.dataLayer = []);
  let eventObj = {
    "event": "ColGradCatalogBrowsePageLoad",
    "pageType": "Col_GradCatalogProductBrowse",
    "schoolType": window?.affiliationData?.customerClassCode,
    "schoolId": window?.affiliationData?.affiliateId,
    "schoolName": extendedName,
  }
  window.dataLayer.push(eventObj);
};

export const shopNowClick  = (catalogName) => {
  let extendedName = "";
  if (window?.affiliationData?.name && window?.affiliationData?.city && window?.affiliationData?.state) {
    extendedName = window?.affiliationData?.name + "(" + window?.affiliationData?.city +"," + window?.affiliationData?.state +")";
  } else if(window?.affiliationData?.name) {
    extendedName = window?.affiliationData?.name
  }

  window && !window.dataLayer && (window.dataLayer = []);
  let eventObj = {
    "event": "ColGradCatalogExploreProductsClick",
    "pageType": "Col_GradCatalogProductBrowse",
    "schoolType": window?.affiliationData?.customerClassCode,
    "schoolId": window?.affiliationData?.affiliateId,
    "schoolName": extendedName,
    "designerProductCategory": catalogName
  }
  window.dataLayer.push(eventObj);
};