import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { EventCalendar } from './eventCalendar'
import { HeroCatalogCounter } from './heroCatalogCounter'
import { domainConverter, heroBannerEvent, getCookieValue, getHostAPI } from '../../util/util'
import {ClassRingCounter} from './classRingCounter'
import { Spinner }from './spinner';
import { actionUrlConverter, alternateUrlConverter } from '../../util/util'
import {Route } from "react-router-dom"

export const HeroBanner = ({ appData:{customerdata}, appData:{storedata} }) => {
	const [loading, setLoading] = useState(false);
	const customerHeroBannerData = customerdata?.filter(data => data.contentType === "SchoolStoreHeroBanner")[0]

	const eventData = storedata?.schoolStoreEvents && heroBannerEvent(storedata.schoolStoreEvents) ? heroBannerEvent(storedata.schoolStoreEvents) : false

	let schoolStoreHeroBannerImageUrl, extendedName, mascotUrl, mascotName, customerClassCode
	if (storedata && storedata.schoolStoreHeroBannerData) {
		schoolStoreHeroBannerImageUrl = storedata.schoolStoreHeroBannerData.schoolStoreHeroBannerImageUrl
		extendedName = storedata.schoolStoreHeroBannerData.extendedName
		mascotUrl = storedata.schoolStoreHeroBannerData.mascotUrl
		mascotName = storedata.schoolStoreHeroBannerData.mascotName
		customerClassCode = storedata.schoolStoreHeroBannerData.customerClassCode
	}
	const [isMascot, setIsMascot] = useState(mascotUrl)
	const [today, setToday] = useState()
	const [eventInfo, setEventInfo] = useState({})
	const [ybCatalogData, setYbCatalogData] = useState({})
	const [jwlEventPromoData,setJwlEventPromoData] = useState({})

	const isCollegeCustomer = customerClassCode?.indexOf('COLLEGE') !== -1
	const isCommercialCustomer = customerClassCode?.indexOf('COMMERCIAL') !== -1
	const showEnhancedHeroBannerType =  isCollegeCustomer || isCommercialCustomer 
	const heroBannerType = showEnhancedHeroBannerType ? 'enhanced':'slim'

	useEffect(() => {
		const host = getHostAPI();
		
		if (host) {
			fetch(`https://${host}/catalog/v1.0/time`)
				.then(response => response.json())
				.then(json => setToday(new Date(parseInt(json))))
				.catch((error) => {
					console.error("Error fetching time: ", error);
				});
			}
	}, []);
	
	const changeSchool = () => {
		document.getElementById("affiliate-change")?.click()
	}

	// YB COUNTER CALCULATIONS
	const ybCatalog = (eventDays) => {
		const ybData = storedata?.productCatalogs?.filter(cat => cat.catalogOsr.toUpperCase() === 'SHOP_YEARBOOK_CUSTOMER_CATALOG')
		if(today && ybData && ybData.length && ybData.length < 2 && getCookieValue('affiliateClassCode') === 'HIGH SCHOOL') {
			const diffSec = Math.ceil((ybData[0].endDate - today.getTime()) / 1000)
			const daysToEnd = Math.floor(diffSec / (3600 * 24))
			const hoursLeftToStart = Math.floor(diffSec / 3600)

			const isCounter = daysToEnd <= 30 && (daysToEnd > 0 || daysToEnd === 0 && hoursLeftToStart > 0) && (!eventDays || daysToEnd < eventDays)

			return {
				isCounter,
				ybData: ybData[0],
				ybDays: daysToEnd,
				ybHours: hoursLeftToStart
			}
		}
		return {
				isCounter: false,
				ybDays: null,
				ybHours: null,
				ybData: null
			}
	}

	// EVENT CALCULATIONS
	const event = () => {
		if(today && eventData) {
			const eventStartDay = new Date(eventData.dateRange.split(' - ')[0])
			const eventEndDay = new Date(eventData.dateRange.split(' - ')[1])
			const eventStartHour = eventData.time.split(" to ")[0]
			const eventEndHour = eventData.time.split(" to ")[1]
			const eventEndDate = new Date(eventEndDay.toDateString('default', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + eventEndHour)
			const eventStartDate = new Date(eventStartDay.toDateString('default', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + eventStartHour)

			// Calculating the time difference between two dates
			let diffInEndTime = Math.ceil((eventEndDate.getTime() - today.getTime()) / 1000)
			let diffInStartTime = Math.ceil((eventStartDate.getTime() - today.getTime()) / 1000)

			// calculate (and substract) whole days
			const daysToStart = Math.floor(diffInStartTime / (3600 * 24))
			const daysToEnd = Math.floor(diffInEndTime / (3600 * 24))


			if (daysToEnd > 0) {
					diffInEndTime -= daysToEnd * 3600 * 24
			}
			if (daysToStart > 0) {
					diffInStartTime -= daysToStart * 3600 * 24
			}

			// calculate (and substract) whole hours
			// const hoursToEnd = Math.floor(diffInEndTime / 3600) % 24
			const hoursToStart = Math.floor(diffInStartTime / 3600) % 24

			return {
				eventDays: daysToStart,
				eventHours: hoursToStart
			}
		}
	}

	useEffect(()=> {
		Boolean(eventData) && today && setEventInfo(event())
		setJwlEventPromoData(jwlEventData)
	}, [today])

	useEffect(()=> {
		eventInfo.eventDays && today && setYbCatalogData(ybCatalog(eventInfo.eventDays))
	}, [eventInfo, today])
	
	const jwlEventData=()=>{
			const jwlEventEndDate = storedata?.jewelryCountdownBanner?.dateAsText;
			if(jwlEventEndDate != null){
				let objectDate = new Date();
				let day = objectDate.getDate();
				let month = objectDate.getMonth();
				let year = objectDate.getFullYear();
				if (day < 10) {
					day = '0' + day;
				}
				if (month < 10) {
					month = `0${month+1}`;
				}
				let currentDate = year + "-" + month + "-" + day;
				//jwl calculation
				const differenceInTime = Math.ceil((new Date(jwlEventEndDate).getTime() - new Date().getTime()) / 1000)
				const differenceInDays = Math.floor(differenceInTime / (3600 * 24))
				const hoursLeftToStartJwlEvent = Math.floor(differenceInTime / 3600)	
				const isjwlCounter = differenceInDays <= 30 &&(differenceInDays > 0 || differenceInDays === 0 && hoursLeftToStartJwlEvent > 0);
				if (jwlEventEndDate == currentDate) {
					return {
						isjwlCounter :true,
						jwlDays: 0,
						jwlHours : 0
					}
				}
				else{
					
					return {
						isjwlCounter,
						jwlDays: differenceInDays+1,
						jwlHours : differenceInTime
				}

				}
			}
		return {
			isjwlCounter :false,
			jwlDays: null,
			jwlHours : null
		}
	}

	const onErrorHanddler = useCallback(
		() => {
			setIsMascot(false)
		},
		[],
	)

	const title = useMemo(() => {
		if (mascotName && mascotName !== 'null') {
			return `Hello, ${mascotName}!`
		} else if (!storedata && window.location.pathname.split("/")[5]) {
			return `Hello, ${window.location.pathname.split("/")[5].replace(/,/g, ' ')}!`
		} else {
			return 'Hello!'
		}
	}, [storedata])

	const heroImage = useMemo(()=>{
		if (eventData?.environment !== 'ONCAMPUS_APPT_EVENT' && eventData?.heroDesktopImgUrl) {
			return <img rel="preload" src={domainConverter(eventData.heroDesktopImgUrl)} alt="Banner background image" />
		} else if (customerHeroBannerData) {
			return (
				<picture>
					<source media="(min-width: 1200px)" srcSet={domainConverter(customerHeroBannerData.imageUrl.xl)} />
					<source media="(min-width: 1024px)" srcSet={domainConverter(customerHeroBannerData.imageUrl.lg)} />
					<source media="(min-width: 768px)" srcSet={domainConverter(customerHeroBannerData.imageUrl.md)} />
					<source media="(min-width: 576px)" srcSet={domainConverter(customerHeroBannerData.imageUrl.sm)} />
					<img rel="preload" src={domainConverter(customerHeroBannerData.imageUrl.xl)} alt={customerHeroBannerData && customerHeroBannerData.imageAltText} />
				</picture>
			)
		} else if (schoolStoreHeroBannerImageUrl) {
			return <img rel="preload" loading="eager" src={domainConverter(schoolStoreHeroBannerImageUrl)} alt="Banner background image" />
		} else {
			return null
		}
	}, [eventData, customerHeroBannerData])

	// console.log(eventData, isCounter)
	return (
		<LazyMotion features={domAnimation}>
			{heroBannerType === 'enhanced' &&
				<div
					className={!eventData && !ybCatalogData.isCounter ? 'school-banner no-event' : 'school-banner'}
					// ref={ref}
				>
					<div className="img-wrapper">
						{ heroImage }
					</div>
					<m.div
						className="overlay"
						animate={{
							opacity: [0, 1],
							transition: {
								duration: .5
							}
						}}
					>
						<div className="inner">
							<m.div
								animate={{
									opacity: [0, 1],
									transition: {
										delay: .5,
										duration: .5,
									},
								}}
								className={ isMascot ? "logo" : "logo no-logo"}
							>
								{
									isMascot && <img src={domainConverter(mascotUrl)} onError={onErrorHanddler} alt="School Mascot" height="auto" width="100%"/>
								}
							</m.div>
							<div>
								<m.h1
									animate={{
										y: [15, 0],
										opacity: [0, 1],
										transition: {
											delay: .8,
											duration: .3,
										},
									}}
								>
										{ title }
								</m.h1>
								<ul>
									<m.li
										animate={{
											y: [15, 0],
											opacity: [0, 1],
											transition: {
												delay: 1.1,
												duration: .3,
											},
										}}
									>
											{ extendedName }
									</m.li>
								</ul>
								<div id ="change-school-herobanner" >
									<a href="#affiliate" class="change-school" onClick = {changeSchool} >Change  School</a>
								</div>
							</div>
						</div>
						{ybCatalogData.isCounter ? <HeroCatalogCounter catalog={ybCatalogData.ybData} days={ybCatalogData.ybDays} hours={ybCatalogData.ybHours}/> : eventData ? <EventCalendar eventData={eventData} hoursToStart={eventInfo.eventHours} daysToStart={eventInfo.eventDays} /> : null}
						{jwlEventPromoData.isjwlCounter ? <ClassRingCounter counter= {jwlEventPromoData.isjwlCounter} days= {jwlEventPromoData.jwlDays} hours = {jwlEventPromoData.jwlHours}/>: null} 
					</m.div>
				</div>
			}
			{heroBannerType === 'slim' &&
				<div className={'slim-school-banner no-event' + (!eventData && !ybCatalogData.isCounter ? ' no-event' : '')} >
					<div className="inner">
						<div className={ isMascot ? "logo" : "logo no-logo"}>
							{
								isMascot && <img src={domainConverter(mascotUrl)} onError={onErrorHanddler} alt="School Mascot" height="auto" width="100%"/>
							}
						</div>
						<div>
							<h1>{ title }</h1>
							<ul>
								<li>{ extendedName }</li>
							</ul>
							<div id ="change-school-herobanner" >
								<a href="#affiliate" class="change-school" onClick = {changeSchool} >Change  School</a>
							</div>
						</div>
					</div>
					{ybCatalogData.isCounter ? <HeroCatalogCounter catalog={ybCatalogData.ybData} days={ybCatalogData.ybDays} hours={ybCatalogData.ybHours}/> : eventData ? <EventCalendar eventData={eventData} hoursToStart={eventInfo.eventHours} daysToStart={eventInfo.eventDays} /> : null}
					{jwlEventPromoData.isjwlCounter ? <ClassRingCounter counter= {jwlEventPromoData.isjwlCounter} days= {jwlEventPromoData.jwlDays} hours = {jwlEventPromoData.jwlHours}/>: null} 
				</div>
			}
		</LazyMotion>
	)
}

export default HeroBanner