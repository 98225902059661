import React from 'react'

export const AdBanner = React.memo(({ appData:{customerdata}, appData:{storedata} }) => {
	
	const customerAdBannerData = customerdata?.filter(data => data.contentType === "SchoolStorePromotion")[0]
	const storeAdBannerData = storedata?.schoolStoreAdBannerData
	const catalogs = storedata?.productCatalogs

	const hasYearbook = Boolean(storeAdBannerData?.hasYearbookCatalog && customerAdBannerData?.yearbook)
	const hasRegalia = Boolean(storeAdBannerData?.hasRegalia && customerAdBannerData?.regalia)
	// const hasJackets = catalogs?.filter(cat => cat.catalogOsr.toUpperCase() === 'HS_JACKETS').length
	// const hasJewelry = catalogs?.filter(cat => cat.catalogOsr.toUpperCase() === 'SHOP_CLASS_RING_CUSTOMER_CATALOG').length
	const paymentPlan = customerAdBannerData?.paymentPlan
		
	return 	<>
				{ hasYearbook   
					?
					<div className="promotion" style={{marginBottom: '2px'}}>
						<div className='container' dangerouslySetInnerHTML={{__html: customerAdBannerData.yearbook}} />
					</div>
				: null 
				}		
				{ hasRegalia ?
					<div className="promotion" style={{marginBottom: '2px'}}>
						<div className='container' dangerouslySetInnerHTML={{__html: customerAdBannerData.regalia}} />
					</div>
				: null
				}
				{ !hasYearbook && !hasRegalia && paymentPlan ?
					<div className="promotion" style={{marginBottom: '2px'}}>
						<div className='container' dangerouslySetInnerHTML={{__html: customerAdBannerData.paymentPlan}} />
					</div>
				: null
				}
				{/* { hasJackets && !hasJewelry ?
					<div className="promotion" style={{marginBottom: '2px'}}>
						<div className='container'>
							<p>🎁 Holiday Shipping | Order Your School <a className="c-link" href="https://www.jostens.com/apps/jcom/router.mvc?code=JKT" rel="noopener noreferrer" data-stringify-link="https://www.jostens.com/apps/jcom/router.mvc?code=JKT" data-sk="tooltip_parent">Jacket</a> by November 5 🎁</p>
						</div> 
					</div>
				: null
				}
				{ !hasJackets && hasJewelry ?
					<div className="promotion" style={{marginBottom: '2px'}}>
						<div className='container'>
							<p>🎁 Holiday Shipping | Order Your School <a className="c-link" href="https://www.jostens.com/apps/jcom/router.mvc?code=RINGS" rel="noopener noreferrer" data-stringify-link="https://www.jostens.com/apps/jcom/router.mvc?code=RINGS" data-sk="tooltip_parent">Jewelry</a> by November 5 🎁</p>
						</div> 
					</div>
				: null
				}				
				{ hasJackets && hasJewelry ?
					<div className="promotion" style={{marginBottom: '2px'}}>
						<div className='container'>
							<p>🎁 Holiday Shipping | Order Your School <a className="c-link" href="https://www.jostens.com/apps/jcom/router.mvc?code=JKT" rel="noopener noreferrer" data-stringify-link="https://www.jostens.com/apps/jcom/router.mvc?code=JKT" data-sk="tooltip_parent">Jacket</a> and <a className="c-link" href="https://www.jostens.com/apps/jcom/router.mvc?code=RINGS" rel="noopener noreferrer" data-stringify-link="https://www.jostens.com/apps/jcom/router.mvc?code=RINGS" data-sk="tooltip_parent">Jewelry</a> by November 5 🎁</p>
						</div>
					</div>
				: null
				} */}
			</>
})

// export default React.memo(AdBanner)