import React, {createContext, useState} from "react"

export const MainContext = createContext({
    loading: false,
    success: false,
    popup: null,
    closePopup: ()=>{},
    openPopup: (payload)=>{},
    closePopup: ()=>{},
    animProgress: {
        id: 0,
        state: 'completed'
    }
})

export const MainContextProvider = ({children}) => {
    const [ popup, setPopup ] = useState(null)
    const [animProgress, setAnimProgress] = useState({
        id: 0,
        state: 'completed'
    })
    const [ isLoading, setLoading ] = useState(false)
    const [ isSuccess, setSuccess ] = useState(false)

    const closePopup = () => {
        document.body.classList.remove('noscroll');
        setPopup(null)
    }
    const openPopup = (payload) => {
        document.body.classList.add('noscroll');
        setPopup(payload)
    }

    return (
        <MainContext.Provider value={{ isLoading, setLoading, isSuccess, setSuccess, popup, openPopup, closePopup, animProgress, setAnimProgress }}>
            {children}
        </MainContext.Provider>
    )
}