import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import * as Routes from '../constants/routes'
import {MainContextProvider} from "../context/mainContext";
import { HomePage } from '../pages/home'
import { Popup } from '../components/advanced/popup'
import Loader from '../components/advanced/loader'
import { AppData } from '../data'
import { GradProductsPage } from "../pages/gradProducts";

const App = () => {

  return (
    <Router>
      <MainContextProvider>
        <Loader />
        <Popup />
          <Switch>
            <Route path={Routes.HOME}>
              {AppData?.isSchoolStorePage ? <HomePage appData={AppData} /> : <GradProductsPage appData={AppData}/>}
            </Route>
          </Switch>
      </MainContextProvider>
    </Router>
  )
}

export default App
