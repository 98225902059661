import React, { Fragment, useCallback, useEffect, useContext, useState } from 'react'
import { domainConverter, actionUrlConverter, isValidEmail } from '../../util/util'
import { MainContext } from "../../context/mainContext"

const getIsMobile = () => window.innerWidth < 992;

export const SalesReps = React.memo(({ appData:{customerdata}, appData:{storedata} }) => {

	const ctx = useContext(MainContext)
	const [isMobile, setIsMobile] = useState(getIsMobile());
	const salesRepData = customerdata?.filter(data => data.contentType === "SchoolStoreReps")[0]
	const schoolStoreReps = storedata?.schoolStoreReps?.sort((a, b) => parseInt(a.orderSeq) > parseInt(b.orderSeq) ? 1 : -1)

	const descrLinkReplacer = useCallback(
		(rep) => {
			let link = rep.linkText
			let p1 = rep.description?.split('<linkText>');
			let p2;
			if(p1?.length > 1 && rep.assignmentTypes && rep.assignmentTypes[0] && rep.assignmentTypes[0] === "YEARBOOKS CUSTOMER SERVICE"){
				let p3 = [];
				p2 = p1[1]?.split('</linkText>');
				if(p1?.length > 2){
					p3 = p1[2]?.split('</linkText>')
				}
				return <p>
					{p1?.[0]}
					<a onClick={linkOnClickHandler} href={actionUrlConverter("/apps/jcom/router.mvc?code=YRBK")}>
						{p2?.[0]}
					</a>
					<span dangerouslySetInnerHTML={{__html: p2?.[1]}}></span>
					{p3?.[0] ? 
						<>
							<a onClick={linkOnClickHandler} href={link ? actionUrlConverter(link) : '#'}>
								{p3[0]}
							</a>
							{p3?.[1]}
						</>
						:""
					}
				</p>
			}
			else if (p1?.length > 1) {
				p2 = p1[1]?.split('</linkText>');
				return <p>
					{p1[0]}
					<a onClick={linkOnClickHandler} href={link ? actionUrlConverter(link) : '#'}>
						{p2[0]}
					</a>
					{p2[1]}
				</p>
			} else {
				return rep.description
			}
		},
		[],
	)

	useEffect(() => {
		if(schoolStoreReps?.length === 3 && schoolStoreReps[2].assignmentTypes[0]==="JOSTENS CUSTOMER SERVICE" && !isMobile){
			const contacts = document.querySelector('.contacts')
			contacts.insertBefore(document.createElement('li'), contacts.children[2])
		}
		const onResize = () => {
				setIsMobile(getIsMobile());
		}

		window.addEventListener("resize", onResize);

		return () => {
				window.removeEventListener("resize", onResize);
		}
	}, [schoolStoreReps, isMobile])

	const linkOnClickHandler = useCallback(
		() => {
			ctx.setLoading(true)
		},
		[],
	)

	return (
		<div className="rep-contact" id="rep-contact">
      <div className="container">
				<div className="img-wrapper">
					<picture>
						<source media="(min-width: 1200px)" srcSet={salesRepData && domainConverter(salesRepData.imageUrl.xl)} />
						<source media="(min-width: 1024px)" srcSet={salesRepData && domainConverter(salesRepData.imageUrl.lg)} />
						<source media="(min-width: 768px)" srcSet={salesRepData && domainConverter(salesRepData.imageUrl.md)} />
						<source media="(min-width: 576px)" srcSet={salesRepData && domainConverter(salesRepData.imageUrl.sm)} />
						<img src={salesRepData && domainConverter(salesRepData.imageUrl.xl)} alt={salesRepData && salesRepData.imageAltText} />
					</picture>
				</div>
			</div>
			<div className="overlay">
				<div className="inner">
					<h2>Contact your sales reps</h2>
					<ul className="contacts">
						{schoolStoreReps?.map((rep, key) => {
							let yb = 0
							// const descr = rep.description?.split('<linkText>Customer Service.</linkText>')
							return (
								<li
									key={`Sales Rep ${key}`}
									style={rep.assignmentTypes[0]==="JOSTENS CUSTOMER SERVICE" && key === 2 && window.innerWidth > 991 ? {gridColumn: '2/2', gridRow: '2/2'} : null}
								>
									<h4 margin="0 10px 0 0">
									{
										rep.assignmentTypes.map((type, key) => {
											if (type.toLowerCase() === 'yearbooks') {
												yb = 1
											}
											return <Fragment key={`AssignmentType-${key}`}>{`${(key > 0) ? ', ' : ''}${type.toUpperCase()}`}</Fragment>
										})
									}
									</h4>
									{/* {rep.linkText && descr?.length > 1 ? <p>{descr[0]}<a onClick={linkOnClickHandler} href={actionUrlConverter(rep.linkText)}>Customer Service.</a>{descr[1]}</p> : <p>{rep.description}</p>} */}
									{descrLinkReplacer(rep)}
									<h3>{rep.salesRepName}</h3>
									<ul>
										{rep.phoneNumber && <li><a href="#">{rep.phoneNumber}</a></li>}
										{rep.emailAddress && isValidEmail(rep.emailAddress)
										?
										<li><a href={`mailto:${rep.emailAddress}`}>{rep.emailAddress}</a></li>
										:
											rep.emailAddress && !isValidEmail(rep.emailAddress)
											?
											<li><a onClick={linkOnClickHandler} href={`https://${window.location.hostname}${rep.emailAddress}`}>{rep.emailAddress.includes('contact-us') ? 'Contact us' : rep.emailAddress}</a></li>
											:
											null
										}
										{/* {yb ? <li><a href={actionUrlConverter('https://www.jostens.com/yearbooklove')}>yearbooklove.com</a></li> : null} */}
									</ul>
								</li>
							)}
						)}
						{/* <li key={`Sales Rep 999`}>
							<h4 margin="0 10px 0 0">Jostens Customer Service</h4>
							<p>If you have questions about Jostens products you have purchased, you can also contact <a href={actionUrlConverter("https://www.jostens.com/contact")}>Customer Service.</a></p>
						</li> */}
					</ul>
				</div>
			</div>
		</div>
	)
})

// export default React.memo(SalesReps)