import React from 'react'
import { domainConverter } from '../../util/util'

export const Seals = React.memo(({ appData:{storedata} }) => {
    
    const isFraternity = storedata?.officiallicensedFraternity && storedata?.officiallicensedFraternity.officiallicensedFraternity
    const isCollegiate = storedata?.officiallicensedCollegiate && storedata?.officiallicensedCollegiate.officiallicensedCollegiate

    if (isFraternity || isCollegiate) {
        return (
            <div className={ !isFraternity || !isCollegiate ? "seals" : "seals items-2" }>
                { isFraternity && 
                <div className="item">
                    <div className="img-wrapper">
                        <img alt="Fraternity Logo" src={domainConverter(storedata.officiallicensedFraternity.officiallicensedFraternityImageUrl)} />
                    </div>
                    <div className="content">
                        <h4>{storedata.officiallicensedFraternity.officiallicensedFraternityTitle}</h4>
                        <div className="text"><p>{storedata.officiallicensedFraternity.officiallicensedFraternityMessage}</p></div>
                    </div>
                </div>}
                { isCollegiate && 
                <div className="item">
                <div className="img-wrapper">
                    <img alt="Fraternity Logo" src={domainConverter(storedata.officiallicensedCollegiate.officiallicensedCollegiateImageUrl)} />
                </div>
                <div className="content">
                    <h4>{storedata.officiallicensedCollegiate.officiallicensedCollegiateTitle}</h4>
                    <div className="text"><p>{storedata.officiallicensedCollegiate.officiallicensedCollegiateMessage}</p></div>
                </div>
            </div>}
            </div>
        )
    } else {
        return null
    }
})

// export default React.memo(Seals)