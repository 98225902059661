import axios from 'axios';
import { LazyMotion, domAnimation, m } from "framer-motion";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Cookies from 'universal-cookie';
import { MainContext } from "../../context/mainContext";
import { shopNowClick } from "../../util/googleAnalytics";
import { actionUrlConverter, alternateUrlConverter, domainConverter, isValidUrl } from '../../util/util';
import { CONTAINER, PRODUCT, SLIDER_VISIBLE_ITEMS, getSliderSettings } from './constants';

export const CatalogList = ( { appData:{storedata}, appData:{customerdata}, appData: {isSchoolStorePage}, catalogData }) => {

    const ctx = useContext(MainContext)
    const mascotName = storedata?.schoolStoreHeroBannerData?.mascotName
    const productCatalog = customerdata?.filter(data=>data.contentType === 'ProductCatalog')
    const catalogs = isSchoolStorePage ? storedata?.productCatalogs : catalogData?.subCatalogs;
    const catalogsQty = catalogs?.filter(pc=>pc?.catalogHidden===false).length;
    const slickRef = useRef(null)
    const inputRef = useRef(null)
    const customerNumber = storedata?.schoolStoreHeroBannerData?.affiliateId
    const cookies = new Cookies();
    const contactUs = `<a href=${alternateUrlConverter('/apps/shop/help/shared/customer_care_index.asp')}>contact us</a>`
    const changeYourSchool = "<a class='affiliate-school' href='/#'>change your school</a>"
    const [catalogsList, setCatalogList] = useState([]);

    const itemsClassName = catalogsQty && catalogsQty > 3 ? '' : ` items-${catalogsQty}`
    const cardsClassName = useMemo(() => (i) => {
        if ((catalogsQty === 2) || (catalogsQty === 3 && i <= 1) || (catalogsQty === 4 && i === 0) ) {
            return ' first'
        } else {
            return ''
        }
    }, [catalogsQty])

    const a11yMaker = useCallback(
        () => {
            const activeCards = slickRef.current.querySelectorAll('.slick-active')
            const inActiveCards = slickRef.current.querySelectorAll('.slick-slide.item:not(.slick-active)')
            Array.from(activeCards).forEach(el => {
                el.setAttribute("tabindex","0");
            });
            Array.from(inActiveCards).forEach(el => {
                el.setAttribute("tabindex","-1");
            });
        },
        [],
    )

    useEffect(() => {
        setTimeout(() => {
            if(document.readyState !== 'loading'){
                ctx.setLoading(false);
            }
        }, 3000)
    }, [])

    useEffect(()=>{
        let list = isSchoolStorePage ? storedata?.productCatalogs : catalogData?.subCatalogs;
        list = list.sort((a,b) => a.displaySequence - b.displaySequence);
        setCatalogList(list)
    },[storedata?.productCatalogs, catalogData?.subCatalogs]);

    useEffect(() => {
        let err = null
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('error') && urlParams.get('error') === 'true') {
            switch (urlParams.get('errorFile')) {
                case 'no-product-error-specific.htm':
                    err = {
                        case:1,
                        message: urlParams.get('errorProductName')
                                ? `${urlParams.get('errorProductName')} are currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                                : `Selected products are currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-product-error.htm':
                    err = {
                        case:2,
                        message: `Product is currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-group-error-specific.htm':
                    err = {
                        case:3,
                        message: urlParams.get('errorGroupName')
                                ? `${urlParams.get('errorGroupName') === '-' ? 'That group' : urlParams.get('errorGroupName')} is currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                                : `Selected catalogs are currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-group-error.htm':
                    err = {
                        case:4,
                        message: `Product collection is currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case null:
                    err = {
                        case:6,
                        message: `Our website encountered an unexpected error. We apologize for the inconvenience. Check to make sure you're at the right school. You can ${changeYourSchool}, browse the products below, or ${contactUs} for additional support.`
                    }
                    break;

                case 'inaccessible-product-error.htm':
                    err = {
                        case:7,
                        message: 'That item is inaccessible. Please sign in as the original owner of that item.'
                    }
                    break;

                case 'no-catalog-products-error.htm':
                    err = {
                        case:8,
                        message: `Actual message: The product you looked for is not currently available for this school. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-customer-found.htm':
                    err = {
                        case:9,
                        message: `Actual Message: Your school or group could not be found.We apologize for the inconvenience. Check to make sure you're at the right school. You can ${changeYourSchool}, browse the products below, or ${contactUs} for additional support.`
                    }
                    break;

                default:
                    break;
            }
        }
        let shwoError = isSchoolStorePage ? (!storedata?.productCatalogs || storedata?.productCatalogs?.length === 0 ? true : false ): !catalogData?.subCatalogs || catalogData?.subCatalogs?.length === 0 ? true : false
        if ( shwoError ) {
            const affilate = document.cookie.replace(/(?:(?:^|.*;\s*)affiliateName\s*\=\s*([^;]*).*$)|^.*$/, "$1") + '|' + document.cookie.replace(/(?:(?:^|.*;\s*)affiliateIds\s*\=\s*([^;]*).*$)|^.*$/, "$1")
            const options = {
                method: "POST",
                mode: 'no-cors',
                headers: new Headers({'Content-Type': 'text/plain'}),
                body: 'There are no Jostens catalogs available for ' + affilate
            };

            fetch(actionUrlConverter('https://devwww.jostens.com/apps/store/report.mvc'), options)

            err = {
                case:5,
                message: 'There are currently no Jostens catalogs available for this school at this time. We apologize for the inconvenience.'
            }
        }

        if (err) {
            console.log(err);
            const errorContainer = document.querySelector('.product-categories .container')
            const h2 = document.querySelector('.product-categories .container h2')
            const toast = `<div class="alert alert-danger alert-icon alert-dismissible fade show" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <span>${err.message}</span>
                            </div>`
            toast && errorContainer.insertAdjacentHTML("afterbegin", toast)
            h2 && errorContainer.prepend(h2)
        }

    }, [changeYourSchool, contactUs, storedata?.productCatalogs, catalogData?.subCatalogs])

    const showDots = (catalogsQty > SLIDER_VISIBLE_ITEMS)
    const slidesToShowAndScroll = catalogsQty >= SLIDER_VISIBLE_ITEMS 
        ? SLIDER_VISIBLE_ITEMS 
        : catalogsQty

    const defaultSettings = {
        slidesToShow: slidesToShowAndScroll,
        slidesToScroll: slidesToShowAndScroll,
        arrows: showDots,
        dots: showDots,
    }

    const additionalSettings = {
        afterChange: a11yMaker,
        variableWidth: !Boolean(catalogsQty === 2),
    }

    const settings = getSliderSettings(defaultSettings, additionalSettings)

    const verifyStudentId = () => {
        let actioUrl='';
        if(document.getElementsByClassName('studentDegree').length>0){
            actioUrl = document.querySelector('.studentDegree').value; 
        }
        if(actioUrl!=undefined && actioUrl!=null && actioUrl!=''){
            document.getElementById("confirmBtn").href =actionUrlConverter(actioUrl);   
            document.getElementById('confirmBtn').click();
        }
        else{
            const url = `${process.env.REACT_APP_API_PATH}/catalog/v1.1/customers/${customerNumber}`+'/students/'+inputRef.current?.value;
            url && axios.get(url)
            .then(result => {          
                if(result && result?.data){
                    document.getElementById("stuName").innerHTML = result.data?.studentName;
                    document.getElementById("stuId").innerHTML = result.data?.studentId;
                    document.getElementById("stuDegree").innerHTML = result.data?.degree;
                    document.getElementById("stuField").innerHTML = result.data?.fieldDegree;   
                    cookies.set('studentId', result.data?.studentId , { path: '/' });
                    if(actioUrl=='' || actioUrl==undefined || actioUrl==null){
                        document.getElementById("confirmBtn").href =result.data?.actionUri;
                    }else{
                        document.getElementById("confirmBtn").href =actionUrlConverter(actioUrl);                    
                    }                
                }
                document.getElementsByClassName('verificationBlkSuccess')[0].style.display = 'block';
                document.getElementsByClassName('rightBlock')[0].style.display = 'none';          
            }).catch(error => {
                document.getElementsByClassName('noStuIDError')[0].style.display = 'block';
                document.getElementsByClassName('missingId')[0].style.display = 'none';            
            })
        }
    }
    const showDegree =() =>{
        document.getElementsByClassName('fa-plus')[0].style.display = 'none'; 
        document.getElementsByClassName('fa-minus')[0].style.display = 'inline-block';
        document.getElementsByClassName('studentDegree')[0].style.display = 'inline-block';
        document.getElementsByClassName('degreeSubmitBtn')[0].style.display = 'inline-block'; 
    }
    const hideDegree =() =>{
        document.getElementsByClassName('fa-plus')[0].style.display = 'inline-block'; 
        document.getElementsByClassName('fa-minus')[0].style.display = 'none'; 
        document.getElementsByClassName('studentDegree')[0].style.display = 'none'; 
        document.getElementsByClassName('degreeSubmitBtn')[0].style.display = 'none';        
    }
    const changeDegree = (e) => {
        if(e.target.value!==''){
            inputRef.current.value = "";
        }
    }
    const changeStudentId = () => {
        if(document.getElementsByClassName('studentDegree').length>0 && inputRef.current.value!==''){
            document.querySelector('.studentDegree').value='';
        }
    }
    const verifyBack = () => {
        inputRef.current.value = "";
        if(document.getElementsByClassName('studentDegree').length>0){
            document.getElementsByClassName('fa-minus')[0].style.display = 'none'; 
            document.getElementsByClassName('studentDegree')[0].style.display = 'none';     
            document.getElementsByClassName('fa-plus')[0].style.display = 'inline-block';  
            document.getElementsByClassName('degreeSubmitBtn')[0].style.display = 'none';     
        }        
        document.getElementsByClassName('rightBlock')[0].style.display = 'block'; 
        document.getElementsByClassName('verificationBlkSuccess')[0].style.display = 'none';         
        document.getElementsByClassName('missingId')[0].style.display = 'block'; 
        document.getElementsByClassName('noStuIDError')[0].style.display = 'none';              
    }
	useEffect(() => {
        if(slickRef.current && slickRef.current.querySelector('.slick-slider')) {
            let visibleItemsCount = 5

            if(catalogsQty >= 5 && catalogsQty <=7) visibleItemsCount = catalogsQty
            if(catalogsQty > 7) visibleItemsCount = 7
                        
            const root = document.documentElement
            const gridElementWidth = Number(slickRef.current.offsetWidth) / visibleItemsCount - 10;

            root.style.setProperty('--slider-element-width', gridElementWidth + "px")
            root.style.setProperty('--slider-element-width-double', gridElementWidth * 2 + "px")

            try {
                const style = document.createElement("style");
                if (document?.head !== null) document.head.appendChild(style);
                if (style?.sheet !== null) style.sheet.insertRule('.slick-disabled{display:none !important;}', 0);
            } catch(error) {
                console.log(error)
            }
            

            a11yMaker()
        }
    }, [slickRef.current, a11yMaker, catalogsQty])

    const imgHandler = useCallback(
        (catOsr) => {
            let url
            if (productCatalog[0]?.images) {
                const cat = productCatalog[0].images.find(el => el.catalogOsr === catOsr)
                url = cat?.imageUrl
            }
            return url
        },
        [productCatalog]
    )

    const cardClickHandler = useCallback(
        (e, catalog) => {
            !isSchoolStorePage && shopNowClick(catalog?.displayName);
            let hasOsr = productCatalog[0]?.excludeList?.hasOwnProperty(catalog.catalogOsr) || false;
            let hasCustomer = false;
            let cookieStudentId = '';
            if(cookies.get('studentId')!=undefined && cookies.get('studentId') != null && cookies.get('studentId')!=''){
                cookieStudentId = cookies.get('studentId');
            }
            if(catalog.catalogOsr==="col_greg"){
                const checkCustomerUrl = `${process.env.REACT_APP_API_PATH}/catalog/v1.1/customers/${customerNumber}/rsv/`;
                axios.get(checkCustomerUrl)
                .then(result => {
                    if(result?.data.option==="REQUIRED" || result?.data.option==="OPTIONAL"){
                        ctx.closePopup()
                        ctx.openPopup({
                            class: 'small',
                            catalogType:'capNGown',
                            title: '',
                            content: <div>
                            <div className="verificationBlk">
                                <img src={`${process.env.REACT_APP_BASE_PATH}/application/themes/jostens/images/capnGown.jpg`} alt="Jostens" className="popUpImage"/>
                            </div>
                            <div className="verificationBlk rightBlock">
                                <form id="verificationForm">
                                    <h6>ENTER STUDENT ID</h6>
                                    <p className="verificationTxt">Enter student ID for degree specific graduation products</p>
                                        <ul>
                                            <li>
                                                <label>Student ID</label>
                                                <span><input type="text" name="studentId" className="email inputElement" ref={inputRef} onChange={changeStudentId} defaultValue={cookieStudentId}/></span>
                                                <span><input type="button" className="verificationBtn submitBtn" value="SUBMIT" onClick={verifyStudentId}/></span>
                                                <p className="noStuIDError" style={{display: 'none'}}>
                                                    Unable to Confirm Your ID <br/>
                                                    Please contact commencement@liberty.edu and <br/>
                                                    place 'Grad Purchase Help' in the subject line.
                                                </p>
                                            </li>
                                            <li className="missingId"><b>Missing ID?</b><p className="verificationTxt">Please contact <a className="commencementlink">commencement@liberty.edu</a> and place 'Grad Purchase Help in the subject line</p></li>
                                            {(result?.data.option==="OPTIONAL" ? 
                                            <>
                                            <li className="searchDegreeLi">
                                                <span className="searchDegreeTxt">Search By Degree</span>
                                                <span className="searchDegreeIcon">
                                                    <i class="fal fa-plus" aria-hidden="true" onClick={showDegree}></i>
                                                    <i class="fal fa-minus" aria-hidden="true" onClick={hideDegree} style={{display: 'none'}}></i>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <select className="studentDegree" onChange={changeDegree}>
                                                        <option value="">Select Degree</option>
                                                        {
                                                            catalog.subCatalogs.map( cat => {
                                                                return <option key={cat.id} value={catalog.actionUrl+'?subCatalog=true&degree='+cat.publishedCatalogId+'&subcatalogDisabled=true'}>{cat.displayName}</option>
                                                            } )
                                                        }
                                                    </select>
                                                </span>
                                                <span><input type="button" className="verificationBtn submitBtn degreeSubmitBtn" value="SUBMIT" id="degreeSubmitBtn" onClick={verifyStudentId} style={{display: 'none'}}/></span>
                                            </li></> : "")
                                            }
                                            
                                        </ul>
                                </form>
                            </div>
                            <div className="verificationBlkSuccess" style={{display: 'none'}}>
                                <form id="studConfirmationForm">
                                    <h4>STUDENT ID CONFIRMATION</h4>
                                    <p>Please confirm this is the correct information.</p>
                                    <div className="studentDetails">
                                        <div className="stdPersonalDetails">
                                            <span>
                                                <label>Student Name</label>
                                                <p id="stuName"></p>
                                            </span>
                                            <span><label>Student ID</label><p id="stuId"></p></span>
                                        </div>
                                        <div className="stdEduDetails">
                                            <span><label>Degree</label><p id="stuDegree"></p></span>
                                            <span><label>Field of Degree</label><p id="stuField"></p></span>
                                        </div>	
                                        <div>
                                            <span><input type="button" className="verificationBtn verificationBckBtn" value="BACK" onClick={verifyBack}/></span>
                                            <span><a href="" className="verificationBtn confirmBtn" id="confirmBtn">CONFIRM</a></span>
                                        </div>
                                    </div>
                                    <div className="verificationSuccessBottom">
                                        <b>Incorrect ID?</b>
                                        <p className="verificationTxt">Please contact <a href="" class="commencementlink">commencement@liberty.edu</a> and place 'Grad Purchase Help in the subject line</p>
                                </div>
                                </form>
                            </div>
                        </div>
                        })
                    }else{
                        if (hasOsr) {
                            let customers = productCatalog[0]?.excludeList[catalog.catalogOsr];
                            hasCustomer = customers.includes(customerNumber) || customers == "ALL"
                        }
                        if (hasCustomer) {
                            ctx.setLoading(true)
                            window.open(actionUrlConverter(catalog.actionUrl), '_self')
                        } else {
                            e.stopPropagation()
                            ctx.openPopup({
                                class: 'small',
                                title: 'Select Your Collection',
                                content: <div className="list-group">
                                            {
                                                catalog.subCatalogs.map( cat => {
                                                    let actionUrl = cat.actionUrl;
                                                    if(actionUrl.includes("?subCatalog=true") && hasOsr) {
                                                        actionUrl = actionUrl + "&router=123"
                                                    } else if(hasOsr) {
                                                        actionUrl = actionUrl + "?router=123"
                                                    }
                                                    return <a key={cat.id} className="list-group-item" href={actionUrlConverter(actionUrl)}>
                                                    <span>{cat.displayName}</span>
                                                    </a>
                                                } )
                                            }
                                        </div>
                            })
                        }
                    }
                }).catch(error => {
                    if (hasOsr) {
                        let customers = productCatalog[0]?.excludeList[catalog.catalogOsr];
                        hasCustomer = customers.includes(customerNumber) || customers == "ALL"
                    }
                    if (hasCustomer) {
                        ctx.setLoading(true)
                        window.open(actionUrlConverter(catalog.actionUrl), '_self')
                    } else {
                        e.stopPropagation()
                        ctx.openPopup({
                            class: 'small',
                            title: 'Select Your Collection',
                            content: <div className="list-group">
                                        {
                                            catalog.subCatalogs.map( cat => {
                                                let actionUrl = cat.actionUrl;
                                                if(actionUrl.includes("?subCatalog=true") && hasOsr) {
                                                    actionUrl = actionUrl + "&router=123"
                                                } else if(hasOsr) {
                                                    actionUrl = actionUrl + "?router=123"
                                                }
                                                return <a key={cat.id} className="list-group-item" href={actionUrlConverter(actionUrl)}>
                                                <span>{cat.displayName}</span>
                                                </a>
                                            } )
                                        }
                                    </div>
                        })
                    }
                })
            }else{
                if (hasOsr) {
                    let customers = productCatalog[0]?.excludeList[catalog.catalogOsr];
                    hasCustomer = customers.includes(customerNumber) || customers == "ALL"
                }
                if (hasCustomer) {
                    ctx.setLoading(true)
                    window.open(actionUrlConverter(catalog.actionUrl), '_self')
                } else {
                    e.stopPropagation()
                    ctx.openPopup({
                        class: 'small',
                        title: 'Select Your Collection',
                        content: <div className="list-group">
                                    {
                                        catalog.subCatalogs.map( cat => {
                                            let actionUrl = cat.actionUrl;
                                            if(actionUrl.includes("?subCatalog=true") && hasOsr) {
                                                actionUrl = actionUrl + "&router=123"
                                            } else if(hasOsr) {
                                                actionUrl = actionUrl + "?router=123"
                                            }
                                            return <a key={cat.id} className="list-group-item" href={actionUrlConverter(actionUrl)}>
                                            <span>{cat.displayName}</span>
                                            </a>
                                        } )
                                    }
                                </div>
                    })            
                } 
            }
        },
        [ctx],
    )

    const cardClickHandlerGetLink = (catalog) => {
            let setLink = null;
            let target = null
            if (catalog.subCatalogs?.length) {
                setLink = ""
                target = ""
            } else {
                if (catalog.alternateUrl) {
                    if((isValidUrl(catalog.alternateUrl)) && (new URL(catalog.alternateUrl)?.href?.indexOf('https'!== -1) || new URL(catalog.alternateUrl)?.href?.indexOf('http'!== -1))){
                        setLink = catalog.alternateUrl
                        target = '_blank'
                        ctx.setLoading(false)
                    }
                    else {
                        setLink = alternateUrlConverter(catalog.alternateUrl)
                        target = '_blank'
                        ctx.setLoading(false)
                    }
                } else {
                    setLink = actionUrlConverter(catalog.actionUrl)
                    target = '_self'
                }
            }
            return {setLink, target};
    }

    const renderCatalog = (key, catalog)=>{
        return (
            <m.div
                className={'item' + cardsClassName(key)}
                style={{cursor: 'pointer'}}
                key={`CatalogItem-${key}`}
                variants={PRODUCT}
            >
                <div className="img">
                    <img loading="lazy" width="100%" height="100%" src={imgHandler(catalog.catalogOsr) ? domainConverter(imgHandler(catalog.catalogOsr)) : domainConverter(catalog.imageUrl)} alt={catalog.displayName} />
                </div>
                {window.innerWidth > 991
                    ?
                    <div className="overlay">
                        <div className="inner">
                            <h3>{catalog.displayName}</h3>
                            <div className="btn btn-sm btn-white btn-white--ghost">{catalog.catalogOsr === 'HS_JACKETS_PROSPECT' ? 'REQUEST NOW' : 'Shop'}</div>
                        </div>
                    </div>
                    :
                    <div className="substring">{catalog.displayName}</div>
                }
            </m.div>
        )
    }

	return (
        <LazyMotion features={domAnimation}>
            <div className={'product-categories' + itemsClassName} >
                <m.div
                    className="container"
                    ref={slickRef}
                    variants={CONTAINER}
                    initial="hidden"
                    animate="show"
                >
                    { mascotName && storedata ? <h2>SHOW YOUR {mascotName} PRIDE</h2> : storedata ? <h2>SHOW YOUR PRIDE</h2> : null}
                    { catalogsQty === 1 &&
                        catalogsList.map((catalog) =>
                            catalog?.catalogHidden ? null :
                                <div class="banner item">
                                    <div class="img">
                                        <img loading="lazy" src={imgHandler(catalog.catalogOsr) ? domainConverter(imgHandler(catalog.catalogOsr)) : domainConverter(catalog.imageUrl)} alt={catalog.displayName} />
                                    </div>
                                    <div className="overlay">
                                        <div className="inner">
                                            <h3>{catalog.displayName}</h3>
                                            <p>{catalog.description}</p>
                                            <div>
                                            {catalog.subCatalogs?.length ? 
                                                <a onClick={(e)=>{cardClickHandler(e, catalog)}} className="btn btn-sm btn-white btn-white--ghost">Shop</a>
                                                : <a onClick={()=>{ctx.setLoading(true);!isSchoolStorePage && shopNowClick(catalog?.displayName);}} href={cardClickHandlerGetLink(catalog).setLink} target={cardClickHandlerGetLink(catalog).target} className="btn btn-sm btn-white btn-white--ghost">Shop</a>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    { catalogsQty > 1 &&
                        <Slider {...settings} className={window.innerWidth < 992 ? "carousel row": "carousel"}>
                            {catalogsList?.map((catalog, key) =>
                                catalog?.catalogHidden ? null :
                                catalog.subCatalogs?.length ? 
                                    <a onClick={(e)=>{cardClickHandler(e, catalog)}} className={window.innerWidth < 992 ? 'col-6 col-md-4 mb-4': ''}>{renderCatalog(key, catalog)}</a>
                                    : <a onClick={()=>{ctx.setLoading(true);!isSchoolStorePage && shopNowClick(catalog?.displayName);}} href={cardClickHandlerGetLink(catalog).setLink} target={cardClickHandlerGetLink(catalog).target} className={window.innerWidth < 992 ? 'col-6 col-md-4 mb-4': ''}>{renderCatalog(key, catalog)}</a>            
                            )}
                        </Slider>
                    }
                </m.div>
            </div>
        </LazyMotion>
	)
}

export default CatalogList