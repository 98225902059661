export const isValidUrl = (url) => {
    try {
      new URL(url)
    } catch (e) {
    //   console.error(e)
      return false
    }
    return true
  }

export const domainConverter = (iUrl) => {
    if (iUrl) {
        if (isValidUrl(iUrl)) {
            const url = new URL(iUrl)
            let host = url.hostname
            switch (window.location.hostname) {
                case 'localhost':
                case 'devwww.jostens.com':
                case 'dev1cms.jostens.com':
                    host = 'devwww.jostens.com'
                    break

                case 'testwww.jostens.com':
                    host = 'testwww.jostens.com'
                    break

                case 'demowww.jostens.com':
                    host = 'democdn.jostens.com'
                    break

                case 'qawww.jostens.com':
                    host = 'qacdn.jostens.com'
                    break

                case 'www.jostens.com':
                    host = 'cdn.jostens.com'
                    break

                default:
                    break;
            }
            return 'https://' + host + url.pathname + url.search + url.hash
        } else {
            return iUrl
        }
    }
    return null
}

export const alternateUrlConverter = (aUrl) => {
    if (isValidUrl(aUrl) && new URL(aUrl).hostname.indexOf('schoolstore.jostens') !== -1) {
        return aUrl
    }
    return actionUrlConverter(aUrl)
}

export const actionUrlConverter = (aUrl) => {
    let host
    if (window.location.hostname === 'dev1cms.jostens.com' || window.location.hostname === 'localhost') {
        host = 'devwww.jostens.com'
    } else if (window.location.hostname === 'uatcms.jostens.com') {
        host = 'demowww.jostens.com'
    } else if (window.location.hostname === 'authcms.jostens.com') {
        host = 'www.jostens.com'
    } else {
        host = window.location.hostname
    }

    if (isValidUrl(aUrl)) {
        const url = new URL(aUrl)
        return window.location.protocol + '//' + host + url.pathname + url.search + url.hash
    } else {
        return window.location.protocol + '//' + host + aUrl
    }
}

export const heroBannerEvent = (events) => {
    return events.filter( e => {
                const dateRange = e.dateRange.split(' - ')
                const endDay = new Date(dateRange[1])
                const today = new Date();
                const days = Math.ceil((endDay.getTime() - today.getTime()) / (1000 * 3600 * 24))
                return days >= 0 && days <= 14 && e.runningTopEvent === true && e.schedulingUrl
            })[0]
}

export const getHostAPI = () => {
    let host 
    switch (window.location.hostname) {
        case 'devwww.jostens.com':
        case 'dev1cms.jostens.com':
                host = 'devapi.jostens.com'
                break

        case 'testwww.jostens.com':
                host = 'testapi.jostens.com'
                break

        case 'demowww.jostens.com':
                host = 'demoapi.jostens.com'
                break

        case 'qawww.jostens.com':
                host = 'qaapi.jostens.com'
                break

        case 'www.jostens.com':
                host = 'api.jostens.com'
                break
        case 'localhost':
        default:
                break;
    }

    return host;
}

export const daysBetweenToday = (d) => {
    const today = new Date(new Date().toLocaleString("en-US", {timeZone: "CST6CDT"}));
    return Math.ceil((d.getTime() - today.getTime()) / (1000 * 3600 * 24))
}

export const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export const isValidEmail = (email) => {
    const validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return validEmail.test(email);
}