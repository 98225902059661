import React from 'react'

export const Breadcrumb = React.memo(({pbData})=> {
    let breadcrumbDetails = [ {
        displayName:"Home",
        url: process.env.REACT_APP_BASE_PATH
    },
    {
        displayName: pbData?.schoolStoreHeroBannerData?.name,
        url: process.env.REACT_APP_BASE_PATH+"/apps/jcom/router.mvc"
    },
    {
        displayName:"Graduation Products",
        url: ""
    }
       
    ]
    return (
        <ol className="breadcrumbs" role="navigation">
            {
                breadcrumbDetails.map((breadcrumbs, index) =>
                    <li key={index}>{breadcrumbs.url ? <a href={breadcrumbs.url}> <span dangerouslySetInnerHTML={{ __html: breadcrumbs.displayName }}/></a> : <span dangerouslySetInnerHTML={{ __html: breadcrumbs.displayName }}/>}</li>
                )
            }
        </ol>
    )
})