import React from 'react';
import ReactDOMServer from 'react-dom/server'
import { PROMOMSG } from '../../constants/rootElements';

const buildPromoMessageDetailList = (promoMessages) => (
    (promoMessages?.length > 0) && (
        <ul className="my-0">
            {promoMessages?.map(promoMessage => <li dangerouslySetInnerHTML={{ __html: promoMessage?.messageDesc }}></li> )}
        </ul>
    )
)

export const renderPromoMessages = (promoMessages) => {
	const promoMsgTextComponent = ReactDOMServer.renderToString(buildPromoMessageDetailList(promoMessages))
	PROMOMSG.render(
		<div className='message-banner info mb-3'>
			<div
				className='banner-header'
				dangerouslySetInnerHTML={{ __html: promoMsgTextComponent }}
			/>
		</div>
	);
}