import React, { useContext, useCallback } from 'react'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { MainContext } from "../../context/mainContext";
import { actionUrlConverter, alternateUrlConverter } from '../../util/util'

export const ClassRingCounter = ( {counter,days,hours}) => {
    const ctx = useContext(MainContext)
    return (
        <LazyMotion features={domAnimation}>
            <m.div
                className="event"
                animate={{
                    y: [30, 0],
                    opacity: [0, 1],
                    transition: {
                        delay: 1.5,
                        duration: .5,
                    },
                }}
            >
                <div className="date-info">
                    { (days > 0 && hours > 0) ? <div>{days}<span>Days Remaining</span></div> : 
                    (days == 0 && hours > 0) ? <div>1<span>Day Remaining</span></div> :<div>LAST DAY</div>}
                </div>
                <div className="detail">
                    <div className="hdr">BEST TIME TO BUY!</div>
                    <h5>SPECIAL CLASS RING OFFER</h5>
                </div>
                <div className="controls">
                    <a href={actionUrlConverter('/apps/jcom/router.mvc?type=K_12&code=RINGS')} className="btn btn-primary btn-primary--ghost">BUY NOW</a>
                </div>
            </m.div>
        </LazyMotion>
    )
}
