export const SLIDER_VISIBLE_ITEMS = 7

export const getSliderSettings = (defaultSettings, additionalSettings) => ({
    ...defaultSettings,
    ...additionalSettings,
    infinite: false,
    responsive: [
        {
            breakpoint: 576,
            settings: "unslick"
        },
        {
            breakpoint: 768,
            settings: "unslick"
        },
        {
            breakpoint: 992,
            settings: "unslick"
        },
        {
            breakpoint: 1200,
            settings: {
                ...defaultSettings,
                variableWidth: false
            }
        }
        ,
        {
            breakpoint: 1440,
            settings: {
                ...defaultSettings,
                variableWidth: false
            }
        }
    ]
})

export const CONTAINER = {
    show: {
        transition: {
            staggerChildren: 0.2
        }
    }
}

export const PRODUCT = {
    hidden: { opacity: 0 },
    show:
    {
        opacity: 1,
        transition: {
            duration: 0.25
        }
    }
}