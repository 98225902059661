import React from 'react'
import { domainConverter } from '../../util/util'

export const CardSection = React.memo(({ appData:{customerdata}, appData:{storedata} }) => {

	const underclassmenEssentialsData = customerdata?.filter(data => data.contentType === "SchoolStoreUnderclassmenEssentials")[0]
	const hasUnderclassmenEssentialsCardBanner =  Boolean(underclassmenEssentialsData && storedata?.schoolStoreRingSizer?.hasUnderclassmenEssentialsBanner)
	const tryOnData = customerdata?.filter(data => data.contentType === "SchoolStoreTryOn")[0]
	const hasTryOnCardBanner = Boolean(storedata?.schoolStoreTryOn?.tryOnBannerUrl && tryOnData)
	
	if ( hasTryOnCardBanner || hasUnderclassmenEssentialsCardBanner ) {
		return (
			<div className={`container card-section${( hasUnderclassmenEssentialsCardBanner && hasTryOnCardBanner ) ? " items-2" : ""}`}>
				{ hasUnderclassmenEssentialsCardBanner && 
					<div className="item">
						<div className="img-wrapper">
                            <div className="bg"></div>
                            <img src={domainConverter(underclassmenEssentialsData?.imageUrl)} alt={underclassmenEssentialsData?.imageAltText} loading="lazy" />
                        </div>
                        <div className="content">
                            <h4>{underclassmenEssentialsData.title}</h4>
                            <div className="text" dangerouslySetInnerHTML={{__html: underclassmenEssentialsData.content}}></div>
                            <div><a href="/products/high-school" target="_blank" className="btn btn-primary btn-primary--ghost" rel="noopener noreferrer">Shop Now</a></div>
                        </div>
					</div>
				}
				{ hasTryOnCardBanner && 
					<div className="item">
						<div className="img-wrapper">
							<div className="bg"></div>
							<img src={domainConverter(tryOnData.imageUrl)} alt={tryOnData?.imageAltText} loading="lazy" />
						</div>
						<div className="content">
							<h4>{tryOnData.title}</h4>
							<div className="text" dangerouslySetInnerHTML={{__html: tryOnData.content}}></div>
							<div><a href={storedata.schoolStoreTryOn.tryOnBannerUrl} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-primary--ghost">CHECK IT OUT</a></div>
						</div>
					</div>
				}
			</div>
		)
	} else {
		return null
	}
})

// export default React.memo()