import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot} from 'react-dom/client';
import './index.css'
import './global.css'
import './customer.css'
import './ecom.css'
import './cms.css'
import App from './app'
import * as serviceWorker from './serviceWorker'

const root = createRoot(  document.getElementById('root'))
root.render(
      <App />
  );

//added to redeploy 07/19/2023
// 05:11 redeploy

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()