import StoreData from './store-data.json'
import CustomerData from './customer-data.json'

const getStoreData = () => {
    if(window.schoolStoreData) {
        return window.schoolStoreData
    } else {
        console.log("No Store Data found on page. Reverting to LOCAL JSON data.")
        return window.location.hostname === "localhost" ? StoreData : undefined
    }
}

const getCustomerData = () => {
    if(window.pageContent) {
        return window.pageContent
    } else {
        console.log("No Customer Data found on page. Reverting to LOCAL JSON data.")
        return window.location.hostname === "localhost" ? CustomerData : undefined
    }
}

const getPageDetail = () => {
    if(window.isSchoolStorePage) {
        return window.isSchoolStorePage
    } else {
        return window.location.hostname === "localhost" ? true : undefined
    }
}
export const AppData = {
    storedata: getStoreData(),
    customerdata: getCustomerData(),
    isSchoolStorePage: getPageDetail()
}
