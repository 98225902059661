import React, { useCallback, useEffect, useContext, useState } from 'react'
import LazyLoad, { forceCheck } from 'react-lazyload'
import { MainContext } from "../../context/mainContext";
import { AdBanner } from './adBanner'
import { PromoMessages } from './promoMessages'
import { HeroBanner } from './heroBanner'
import { UpcomingEvents } from './upcomingEvents'
import { CatalogList } from './catalogList'
import { TopSellers } from './topSellers'
import { SingleBanner } from './singleBanner'
import { VideoHero } from './videoHero'
import { CardSection } from './cardSection'
import { SalesReps } from './salesReps'
import { Seals } from "./seals"
import { OlapicList } from './olapicList'
import { Combo } from './combo'

// const AdBanner = React.lazy(() => import("./adBanner"))
// const PromoMessages = React.lazy(() => import("./promoMessages"))
// const HeroBanner = React.lazy(() => import("./heroBanner"))
// const UpcomingEvents = React.lazy(() => import("./upcomingEvents"))
// const CatalogList = React.lazy(() => import("./catalogList"))
// const TopSellers = React.lazy(() => import("./topSellers"))
// const SingleBanner = React.lazy(() => import("./singleBanner"))
// const VideoHero = React.lazy(() => import("./videoHero"))
// const CardSection = React.lazy(() => import("./cardSection"))
// const SalesReps = React.lazy(() => import("./salesReps"))
// const Seals = React.lazy(() => import("./seals"))
// const OlapicList = React.lazy(() => import("./olapicList"))
// const Combo = React.lazy(() => import("./combo"))


export const HomePage = props => {
	const { appData } = props
	const ctx = useContext(MainContext)
	const catalogsQty = appData.storedata?.productCatalogs?.length
	const isTryOn = Boolean(appData.customerdata?.find(data => data.contentType === "SchoolStoreTryOn") && appData.storedata?.schoolStoreTryOn?.tryOnBannerUrl)
	const isUnderclassmenEssentials = Boolean(appData.customerdata?.find(data => data.contentType === "SchoolStoreUnderclassmenEssentials") && appData.storedata?.schoolStoreRingSizer?.hasUnderclassmenEssentialsBanner)
	const hasCardBannerSection = Boolean(isTryOn || isUnderclassmenEssentials)
	const isAddBanner = Boolean(appData.customerdata?.find(data => data.contentType === "SchoolStorePromotion"))
	const isEvents = Boolean(appData.customerdata?.find(data => data.contentType === "SchoolEvents"))
	const isTopSellers = Boolean(appData.customerdata?.find(data => data.contentType === "ProductTopSellers"))
	const isReps = Boolean(appData.customerdata?.find(data => data.contentType === "SchoolStoreReps"))
	const isOlapic = Boolean(appData.customerdata?.find(data => data.contentType === "SchoolStoreOlapic"))
	const isSeal = Boolean(appData.customerdata?.find(data => data.contentType === "SchoolStoreSeal"))
	const isVideo = Boolean(appData.customerdata?.find(data => data.contentType === "MediaCallout"))
	const isBannerSection = Boolean(appData.customerdata?.find(data => data.contentType === "BannerSection"))

	const [loadGrid, setLoadGrid] = useState(false)
	const handleScroll = () => {
		setLoadGrid(true);
		removeScrollEvent();
	};
	const addScrollEvent = () => {
		window.addEventListener('scroll', handleScroll);
	}
	const removeScrollEvent = () => {
		window.removeEventListener('scroll', handleScroll);
	}
	
	useEffect(()=>{
		if(document.body.clientWidth<1980) {
			addScrollEvent();
		} else {
			setLoadGrid(true);
		}
		return () => removeScrollEvent();
	},[])

	const linkOnClickHandler = useCallback(
		() => {
			ctx.setLoading(true)
		},
		[],
	)

	useEffect(() => {
		const setSitemap = setTimeout(()=>{
			forceCheck()
			const cLinks = document.querySelectorAll('.footer-link>a')
			const siteMap = document.querySelector('a[href="/site-map"]')
			siteMap && siteMap.setAttribute('href', '/apps/store/sitemap/-/-/')
			cLinks.forEach(link => {
				link.addEventListener('click', linkOnClickHandler, false);
			});
		}, 1500)
		return ()=>{clearTimeout(setSitemap)}
	}, [])

	return (
		<>
		{/* <Suspense fallback={<div>Loading...</div>}> */}
			{isAddBanner && <AdBanner appData={appData}/>}
			<PromoMessages appData={appData} />
			<HeroBanner appData={appData} />

			{ isEvents && <UpcomingEvents appData={appData} /> }

			{
				((!hasCardBannerSection) || catalogsQty >= 3 || !catalogsQty) &&
					<LazyLoad offset={100} height={500} once resize>
						<CatalogList appData={appData} />
					</LazyLoad>
			}

			{
				((hasCardBannerSection) && (catalogsQty === 1 || catalogsQty === 2)) &&
					<LazyLoad offset={100} height={500} once >
						<Combo appData={appData} />
					</LazyLoad>
			}

			{isTopSellers && loadGrid &&
			<LazyLoad offset={100} height={500} once resize>
				<TopSellers appData={appData} />
			</LazyLoad>}

			{isBannerSection && loadGrid &&
			<LazyLoad offset={100} height={500} once>
				<SingleBanner appData={appData} />
			</LazyLoad>}

			{isVideo && loadGrid &&
			<LazyLoad offset={100} height={500} once>
				<VideoHero appData={appData} />
			</LazyLoad>}

			{
				((hasCardBannerSection) && (catalogsQty === 0 || catalogsQty >= 3)) &&
					<LazyLoad offset={100} height={500} once>
						<CardSection appData={appData} />
					</LazyLoad>
			}

			{isReps && loadGrid &&
			<div id="salesRep">
				<LazyLoad offset={100} height={500} once>
					<SalesReps appData={appData} />
				</LazyLoad>
			</div>}

			{isSeal && loadGrid &&
			<LazyLoad offset={100} height={500} once>
				<Seals appData={appData} />
			</LazyLoad>}

			{isOlapic && loadGrid &&
			<LazyLoad offset={100} height={500} once>
				<OlapicList appData={appData} />
			</LazyLoad>}
		{/* </Suspense> */}
		</>
	)
}
