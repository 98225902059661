import React, { useEffect, useState, useMemo } from 'react'

export const OlapicList = ({ appData:{storedata}, appData:{customerdata} }) => {
    const [schoolStoreOlapic, setSchoolStoreOlapic] = useState(null)

    const getSchoolStoreOlapic = useMemo(() => customerdata?.filter(data => data.contentType === "SchoolStoreOlapic"), [customerdata])

	useEffect(() => {
        if (storedata && storedata.schoolStoreOlapic && storedata.schoolStoreOlapic !== 'null') {
            setSchoolStoreOlapic(storedata.schoolStoreOlapic)
        } else if (getSchoolStoreOlapic?.lenght) {
            setSchoolStoreOlapic(getSchoolStoreOlapic[0])
        }
	}, [storedata])

    useEffect(() => {
        if (schoolStoreOlapic) {
            const styleEl = document.createElement('style')
            document.head.appendChild(styleEl)
            styleEl.sheet.insertRule('.olapic-slider-header, .olapic-see-all, .olapic-slider-copy, .olapic-partial-item { display: none !important; }', 0)
            styleEl.sheet.insertRule(`.olapic #olapic-slider-widget .olapic-footer-buttons .olapic-footer-button > a { 
                                        text-decoration: none !important;
                                        width: 150px !important; 
                                        color: black !important; 
                                        background: transparent !important; 
                                        border-radius: 24px !important; 
                                        border: 2px solid #2E2E2E; !important; 
                                        font-weight: 600 !important;
                                        text-transform: uppercase !important;
                                        height: auto !important;
                                        min-height: 2.5rem !important;
                                        font-size: 0.875rem !important;
                                        line-height: 1 !important;
                                        padding: 0.6875rem 1rem !important;
                                        text-align: center !important;
                                        vertical-align: middle !important;
                                        user-select: none !important; }`
                                    , 1)
            styleEl.sheet.insertRule(`.olapic #olapic-slider-widget .olapic-footer-buttons .olapic-footer-button > a:hover:not(:disabled) { 
                                        background-color: #2E2E2E !important;
                                        border-color: #2E2E2E !important;
                                        color: #fff !important; }`
                                    , 2)
            styleEl.sheet.insertRule(`a.olapic-nav-button.olapic-nav-prev, a.olapic-nav-button.olapic-nav-next { 
                                        color: #031d40 !important;
                                        width: 40px !important;
                                        height: 40px !important;
                                        box-shadow: none !important;
                                        -webkit-box-shadow: none !important;
                                        line-height: 40px !important;
                                        border: 2px solid #d6dbe0 !important;
                                        border-radius: 50% !important;
                                        font-size: 0.75rem !important;
                                        display: flex !important;
                                        justify-content: center !important;
                                        align-items: center !important;
                                        background-color: transparent !important; }`
                                    , 3)
            styleEl.sheet.insertRule(`a.olapic-nav-button.olapic-nav-next:hover, a.olapic-nav-button.olapic-nav-prev:hover { 
                                        color: #031d40 !important; }`
                                    , 4)
            styleEl.sheet.insertRule(`a.olapic-nav-button.olapic-nav-prev::before, a.olapic-nav-button.olapic-nav-next::before {  
                                        display: block !important;
                                        left: 0 !important;
                                        line-height: 1 !important;
                                        font-weight: 900 !important;
                                        box-sizing: border-box !important; }`
                                    , 5)
            styleEl.sheet.insertRule(`a.olapic-nav-button.olapic-nav-prev::before { 
                                        content: '\f060' !important; }`
                                    , 6)
            styleEl.sheet.insertRule(`a.olapic-nav-button.olapic-nav-next::before { 
                                        content: '\f061' !important; }`
                                    , 7)
            styleEl.sheet.insertRule(`.olapic #olapic-slider-widget .olapic-carousel li { 
                                        width: 280px !important;
                                        height: 280px !important;}`
                                    , 8)
            styleEl.sheet.insertRule(`.olapic #olapic-slider-widget .olapic-carousel li .olapic-user-name { 
                                        max-width: 280px !important;
                                        line-height: 280px !important;}`
                                    , 9)
            styleEl.sheet.insertRule(`.olapic #olapic-slider-widget.olapic-slider-widget, .olapic #olapic-slider-widget .olapic-slider-body { 
                                        max-width: 1920px !important;}`
                                    , 10)

            const script = document.createElement('script')
            const olapic = document.querySelector('.olapic-widget')
            script.type = "text/javascript"
            script.src = "https://photorankstatics-a.akamaihd.net/743d2e78a76dedeb07e0745158547931/static/frontend/latest/build.min.js"
            script.async = true
            script.setAttribute("data-olapic", "olapic_specific_widget")
            script.setAttribute("data-instance", schoolStoreOlapic.olapicInstance )
            script.setAttribute("data-apikey", schoolStoreOlapic.olapicApikey )
            olapic.appendChild(script);
        }
	}, [schoolStoreOlapic])

    if(schoolStoreOlapic) {
        return  <article className="olapic-widget unaffiliated">
                    <div className="inner">
                        <div className="olapic-widget__header">
                            <h2 className="h3 olapic_widget__heading"> {schoolStoreOlapic.olapicHashTag || '#VOLS'} </h2>
                            <div className="olapic_widget__subheading">The Most Trusted Partner In&nbsp;Creating Moments That Matter&nbsp;</div>
                        </div>
                        <div id="olapic_specific_widget"></div>
                    </div>
                </article>
    } else return null
}

export default OlapicList