
import React, { useState, useContext, useCallback, useMemo } from 'react'
// import { useInView } from 'react-intersection-observer'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { MainContext } from "../../context/mainContext";
import { domainConverter, actionUrlConverter, heroBannerEvent, daysBetweenToday } from '../../util/util'

export const UpcomingEvents = ({ appData:{customerdata}, appData:{storedata} }) => {

	const ctx = useContext(MainContext)
	const [expanded, setExpanded] = useState(false)
	const upcomingEventsData = useMemo(() => customerdata?.filter(data => data.contentType === "SchoolEvents")[0], [customerdata])
	// const allEvents = useMemo(() => storedata?.schoolStoreEvents?.filter( e => daysBetweenToday(new Date(e.dateText))), [storedata?.schoolStoreEvents])
	const allEvents = storedata?.schoolStoreEvents
	let upcomingEvents = []

	// Removing events with a duplicate eventId
	const removeDuplicatedEvents = useCallback(
		(arr, prop) => {
			let oe = []
			let compariosionObj = {}
			for (const i in arr) {
				compariosionObj[arr[i][prop]] = arr[i]
			}
			for(const i in compariosionObj) {
				oe.push(compariosionObj[i])
			}
			 return oe
		},
		[],
	)

	const linkOnClickHandler = useCallback(
		() => {
			ctx.setLoading(true)
			ctx.closePopup()
		},
		[],
	)

	let originalEvents = removeDuplicatedEvents(storedata?.schoolStoreEvents, 'eventId')

	//Removing outdated events
	originalEvents = originalEvents.filter( e => daysBetweenToday(new Date(e.dateRange.split(' - ')[1])) >= 0)

	// Removing an event thats already on Hero
	if (originalEvents && originalEvents.length) {
		const heroEventData = heroBannerEvent(originalEvents)
		const restOfEvents = originalEvents.filter((e) => e.orderSeq !== heroEventData?.orderSeq)
		upcomingEvents = restOfEvents.sort((a, b) => a.orderSeq > b.orderSeq ? 1 : -1).slice(0, 4)
	}

	const seeAllEventsHandler = useCallback(
		() => {
			ctx.openPopup({
				class: 'fullscreen',
				title: 'All Events',
				content:	<>
								<div className="hdr">
									<div className="container">
										<div className="row justify-content-center">
											<div className="col-xs-12 col-lg-10 col-xl-8">
												{/* <h4>Upcoming Event Details</h4> */}
												<h1>All Events</h1>
												{/* <p>March 2, 2021 - March 3, 2021</p> */}
												<p className="info"><span className="fas fa-question-circle mr-1"></span> Questions? <a onClick={linkOnClickHandler} href={actionUrlConverter('https://www.jostens.com/customer-service/contact-your-sales-rep')}>Contact your rep.</a></p>
											</div>
										</div>
									</div>
								</div>
								<div className="search-results">
									<div className="container">
										<div className="row justify-content-center">
											<div className="col-xs-12 col-lg-10 col-xl-8">
												<div className="table-responsive">
													<table className="table">
														<thead>
															<tr>
																<th>Event</th>
																<th>Date</th>
																<th>Time</th>
																<th>Location</th>
																<th>Notes</th>
															</tr>
														</thead>
														<tbody>
															{allEvents?.map(d =>
																{
																	const isAppointment = Boolean(d.location?.toUpperCase() === 'BY APPOINTMENT' && d.schedulingUrl)
																	return (
																		<tr
																		key={d.orderSeq}
																		tabIndex={isAppointment ? '0' : '-1'}
																		onKeyPress={isAppointment ? (e)=>{rowKeyPressHandler(e, d)} : undefined}
																		onClick={ isAppointment ? ()=>bookAppointmentHandler(d) : undefined}
																		style={isAppointment ? {cursor:'pointer'} : {cursor:'default'}}
																		>
																			<td>{d.name}</td>
																			<td>{new Date(d.dateText).toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
																			<td>{d.time ? d.time : d.environment !== "Delivery Estimate" ? "" : "Specific Date and Time to be Confirmed"}</td>
																			<td style={isAppointment ? {textDecoration: 'underline'} : {textDecoration: 'none'}}>{d.location}</td>
																			<td>{d.consumerPublicNotes}</td>
																		</tr>)
																}
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
			})
		},
		[allEvents],
	)

	const seeDetails = useCallback(
			(e) => {
				const eventDetails = allEvents.filter(data => data.eventId === e.eventId)
				const dateRangeArr = e.dateRange.split(' - ')
				const dateRange = dateRangeArr[0] === dateRangeArr[1] ?  dateRangeArr[0] : e.dateRange;
				ctx.openPopup({
					class: 'fullscreen',
					title: e.name,
					content:	<>
									<div className="hdr">
										<div className="container">
											<div className="row justify-content-center">
												<div className="col-xs-12 col-lg-10 col-xl-8">
													<h4>Upcoming Event Details</h4>
													<h1>{e.name}</h1>
													<p>{dateRange}</p>
													<p className="info"><span className="fas fa-question-circle mr-1"></span> Questions? <a onClick={linkOnClickHandler} href={actionUrlConverter('https://www.jostens.com/customer-service/contact-your-sales-rep')}>Contact your rep.</a></p>
												</div>
											</div>
										</div>
									</div>
									<div className="search-results">
										<div className="container">
											<div className="row justify-content-center">
												<div className="col-xs-12 col-lg-10 col-xl-8">
													<div className="table-responsive">
														<table className="table">
															<thead>
																<tr>
																	<th>Date</th>
																	<th>Location</th>
																	<th>Time</th>
																	<th>Notes</th>
																</tr>
															</thead>
															<tbody>
																{eventDetails.map(d =>{
																	const dateText = new Date(d.dateText).toLocaleString('default', { year: 'numeric', month: 'long', day: 'numeric' })
																	return (
																	<tr key={d.orderSeq} >
																		<td>{d.environment === 'Delivery Estimate' ? d.dateRange : dateText}</td>
																		<td>{d.location}</td>
																		<td>{d.time ? d.time : d.environment !== "Delivery Estimate" ? "" : "Specific Date and Time to be Confirmed"}</td>
																		<td>{d.consumerPublicNotes}</td>
																	</tr>)}
																)}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
				})
			},
		[],
	)

	const bookAppointmentHandler = useCallback(
        (day) => {
			ctx.closePopup()
            ctx.openPopup({
                class: 'small',
                title: 'Book Appointment',
                content:  <iframe id="bookAppointmentIFrame"
															title="Book Appointment"
															width="100%"
															height="350px"
															frameBorder="0"
															src={day.schedulingUrl}>
													</iframe>
            })
        },
        [],
    )

	const rowKeyPressHandler = useCallback(
        (e, day) => {
            if(e.key === 'Enter' || e.key === 'Space'){
                bookAppointmentHandler(day)
            }
        },
        [],
    )

	if( upcomingEvents.length ) {
		return (
			<LazyMotion features={domAnimation}>
				<div
					className={`events${upcomingEventsData?.imageUrl?'':' no-img'}`}
					// ref={ref}
				>
					{ upcomingEventsData?.imageUrl &&
					<div className="img" >
							<m.img
								src={domainConverter(upcomingEventsData?.imageUrl)}
								alt={upcomingEventsData?.imageAltText}
								animate={{
									y: [30, 0],
									opacity: [0, 1],
									transition: {
										duration: .5,
									},
								}}
							/>
						</div>
					}
					<div className="inner">
						<header>
							<h2>Upcoming Events</h2>
							<ul>
								{/* <li><a href="#promotion-banner" className="btn btn-sm"><i className="fas fa-arrow-down"></i> Subscribe for more updates</a></li> */}
								<li><a href="#salesRep" className="btn btn-sm"><i className="fas fa-arrow-down"></i> Questions? Contact Jostens</a></li>
								<li><div onClick={seeAllEventsHandler} className="btn btn-sm btn-primary btn-primary--ghost">View All Events</div></li>
							</ul>
						</header>
						<ul className={`items${expanded ? ` expanded` : ""}`}>
							{upcomingEvents.map((event, key) =>
							 {
								 if(event.environment === 'Delivery Estimate')
                              {
								var currentDate=new Date();
								var eventDate=new Date(event.dateText);
								var diffrence = currentDate.getTime()-eventDate.getTime();
								var priorDays = Math.ceil(diffrence / (1000 * 3600 * 24));
								if(priorDays<14)
								{
								return  (
								<m.li
									key={`Upcoming-Event_${key}`}
									animate={{
										opacity: [0, 1],
										transition: {
											delay: key*.25,
											duration: .25,
										},
									}}
								>
									<h4>{event.name}</h4>
									<div>{event.dateRange.split(' - ')[1] ? event.dateRange.split(' - ')[0].replace(/,/g, ', ') + " - " + event.dateRange.split(' - ')[1] : event.dateRange.replace(/,/g, ', ')}</div>
									<div><a onClick={()=>seeDetails(event)} style={{textDecoration:'underline',cursor:'pointer'}}>See details</a></div>
								</m.li>)
								}
							  }
							else{
								return  (
									<m.li
										key={`Upcoming-Event_${key}`}
										animate={{
											opacity: [0, 1],
											transition: {
												delay: key*.25,
												duration: .25,
											},
										}}
									>
										<h4>{event.name}</h4>
										<div>{event.dateRange.split(' - ')[1] === event.dateRange.split(' - ')[0] ? event.dateRange.split(' - ')[0].replace(/,/g, ', ') : event.dateRange.replace(/,/g, ', ')}</div>
										<div><a onClick={()=>seeDetails(event)} style={{textDecoration:'underline',cursor:'pointer'}}>See details</a></div>
									</m.li>)
							}
							}
							)}
						</ul>
					</div>
					{/* This section is mobile only */}
					{!expanded && upcomingEvents.length > 2 && <button className="btn" type="button" onClick={() => setExpanded(!expanded)}>
						Show more events
						<span className="fa-stack">
							<span className="fa fa-circle fa-stack-2x"></span>
							<strong className="fa-stack-1x">{upcomingEvents.length - 2}</strong>
						</span>
					</button>}
					<div className="controls">
						{/* <a href="#promotion-banner" className="btn btn-sm"><i className="fas fa-arrow-down"></i> Subscribe for more updates</a> */}
						<a href="#salesRep" className="btn btn-sm"><i className="fas fa-arrow-down"></i> Questions? Contact Jostens</a>
					</div>
					{/* end mobile only */}
				</div>
			</LazyMotion>
		)
	} else return null
}

export default UpcomingEvents