import React, { useState, useEffect, useContext, useCallback } from 'react'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { MainContext } from "../../context/mainContext"
import { domainConverter, actionUrlConverter, alternateUrlConverter } from '../../util/util'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const Combo = ( { appData:{storedata}, appData:{customerdata} }) => {

    const ctx = useContext(MainContext)
    const mascotName = storedata?.schoolStoreHeroBannerData?.mascotName
    const productCatalog = customerdata?.filter(data=>data.contentType === 'ProductCatalog')
    const catalogsQty = storedata?.productCatalogs?.length
    const underclassmenEssentials = storedata?.schoolStoreRingSizer?.hasUnderclassmenEssentialsBanner
    const underclassmenEssentialsData = customerdata?.filter(data => data.contentType === "SchoolStoreUnderclassmenEssentials")[0]
    const schoolStoreTryOn = storedata?.schoolStoreTryOn?.tryOnBannerUrl
	const tryOnData = customerdata?.filter(data => data.contentType === "SchoolStoreTryOn")[0]
    const isUnderclassmenEssentials = Boolean(underclassmenEssentials && underclassmenEssentialsData)
    const isTryOn = Boolean(schoolStoreTryOn && tryOnData)
    const customerNumber = storedata?.schoolStoreHeroBannerData?.affiliateId

    const contactUs = `<a href=${alternateUrlConverter('/apps/shop/help/shared/customer_care_index.asp')}>contact us</a>`
    const changeYourSchool = "<a class='affiliate-school' href='/#'>change your school</a>"

    let comboCase
    switch (catalogsQty) {
        case 1:
            if (isTryOn && isUnderclassmenEssentials) {
                comboCase = 12
            } else {
                comboCase = 11
            }
            break;
        case 2:
            if (isTryOn && isUnderclassmenEssentials) {
                comboCase = 22
            } else {
                comboCase = 21
            }
            break;
        default:
            break;
    }

    const itemsClassName = catalogsQty === 1 ? ` categories-1 cards-${isTryOn && isUnderclassmenEssentials ? '2' : '1'}` : ` categories-2 cards-${isTryOn && isUnderclassmenEssentials ? '2' : '1'}`

    useEffect(() => {
        let err = null
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('error') && urlParams.get('error') === 'true') {
            switch (urlParams.get('errorFile')) {
                case 'no-product-error-specific.htm':
                    err = {
                        case:1,
                        message: urlParams.get('errorProductName')
                                ? `${urlParams.get('errorGroupName') === '-' ? 'That group' : urlParams.get('errorGroupName')} is currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                                : `Selected products are currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-product-error.htm':
                    err = {
                        case:2,
                        message: `Product is currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-group-error-specific.htm':
                    err = {
                        case:3,
                        message: urlParams.get('errorGroupName')
                                ? `${urlParams.get('errorGroupName')} are currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                                : `Selected catalogs are currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-group-error.htm':
                    err = {
                        case:4,
                        message: `Product collection is currently not available to purchase online. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case null:
                    err = {
                        case:6,
                        message: `Our website encountered an unexpected error. We apologize for the inconvenience. Check to make sure you're at the right school. You can ${changeYourSchool}, browse the products below, or ${contactUs} for additional support.`
                    }
                    break;

                case 'inaccessible-product-error.htm':
                    err = {
                        case:7,
                        message: 'That item is inaccessible. Please sign in as the original owner of that item.'
                    }
                    break;

                case 'no-catalog-products-error.htm':
                    err = {
                        case:8,
                        message: `Actual message: The product you looked for is not currently available for this school. Please ${contactUs} for product availability and ordering information.`
                    }
                    break;

                case 'no-customer-found.htm':
                    err = {
                        case:9,
                        message: `Actual Message: Your school or group could not be found.We apologize for the inconvenience. Check to make sure you're at the right school. You can ${changeYourSchool}, browse the products below, or ${contactUs} for additional support.`
                    }
                    break;

                default:
                    break;
            }
        }
        if (!storedata?.productCatalogs || storedata?.productCatalogs?.length === 0) {
            const affilate = document.cookie.replace(/(?:(?:^|.*;\s*)affiliateName\s*\=\s*([^;]*).*$)|^.*$/, "$1") + '|' + document.cookie.replace(/(?:(?:^|.*;\s*)affiliateIds\s*\=\s*([^;]*).*$)|^.*$/, "$1")
            const options = {
                method: "POST",
                mode: 'no-cors',
                headers: new Headers({'Content-Type': 'text/plain'}),
                body: 'There are no Jostens catalogs available for ' + affilate
            };

            fetch(actionUrlConverter('https://devwww.jostens.com/apps/store/report.mvc'), options)

            err = {
                case:5,
                message: 'There are currently no Jostens catalogs available for this school at this time. We apologize for the inconvenience.'
            }
        }

        if (err) {
            console.log(err);
            const container = document.querySelector('.product-categories .container')
            const h2 = document.querySelector('.product-categories .container h2')
            const toast = `<div class="alert alert-danger alert-icon alert-dismissible fade show" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <span>${err.message}</span>
                            </div>`
            container.insertAdjacentHTML("afterbegin", toast)
            container.prepend(h2)
        }

        const style = document.createElement("style");
        document.head.appendChild(style);
        style.sheet.insertRule('.slick-disabled{display:none !important;}', 0);

        const activeCards = document.querySelectorAll('.slick-active')
        Array.from(activeCards).forEach(el => {
            el.setAttribute("tabindex","0");
        });

    }, [storedata.productCatalogs, changeYourSchool, contactUs])

    // const showXlDots = (catalogsQty > 4);
    // const showLgDots = (catalogsQty > 4);
    // const showMdDots = (catalogsQty > 3);
    // const showSmDots = (catalogsQty > 2);
    let settings = {
        accessibility: false,
        slidesToShow: catalogsQty,
        slidesToScroll: 0,
        arrows: false,
        dots: false,
        variableWidth: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 0,
                    dots: false,
                    arrows: false,
                    variableWidth: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: catalogsQty,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                    variableWidth: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: catalogsQty,
                    slidesToScroll: catalogsQty,
                    dots: false,
                    arrows: false,
                    variableWidth: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: catalogsQty,
                    slidesToScroll: catalogsQty,
                    dots: false,
                    arrows: false,
                    variableWidth: false
                }
            }
            ,
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: catalogsQty,
                    slidesToScroll: catalogsQty,
                    dots: false,
                    arrows: false,
                    variableWidth: false
                }
            }
        ]
    }

    const imgHandler = useCallback(
        (catOsr) => {
            let url
            if (productCatalog[0]?.images) {
                const cat = productCatalog[0].images.find(el => el.catalogOsr === catOsr)
                url = cat?.imageUrl
            }
            return url
        },
        [productCatalog]
    )

    const container = {
        show: {
          transition: {
            staggerChildren: 0.2
          }
        }
    }

    const product = {
        hidden: { opacity: 0 },
        show:
            {
                opacity: 1,
                transition: {
                    duration: 0.25
                }
            }
    }

    const cardClickHandler = useCallback(
        (e, catalog) => {
            let hasOsr = productCatalog[0]?.excludeList?.hasOwnProperty(catalog.catalogOsr) || false;
            let hasCustomer = false;
            if (hasOsr) {
                let customers = productCatalog[0]?.excludeList[catalog.catalogOsr];
                hasCustomer = customers.includes(customerNumber) || customers == "ALL"
            }
            if (hasCustomer) {
                ctx.setLoading(true)
                window.open(actionUrlConverter(catalog.actionUrl), '_self')
            } else {
                e.stopPropagation()
                if (catalog.subCatalogs?.length) {
                    ctx.openPopup({
                        class: 'small',
                        title: 'Select Your Collection',
                        content:<div className="list-group">
                                    {
                                        catalog.subCatalogs.map( cat => <a key={cat.id} className="list-group-item" href={actionUrlConverter(cat.actionUrl)}><span>{cat.displayName}</span></a> )
                                    }
                                </div>
                    })
                } else {
                    if (catalog.alternateUrl) {
                        window.open(alternateUrlConverter(catalog.alternateUrl), '_blank', 'noopener,noreferrer')
                    } else {
                        ctx.setLoading(true)
                        window.open(actionUrlConverter(catalog.actionUrl), '_self')
                    }
                }
            }
        },
        [ctx],
    )

    const cardKeyPressHandler = useCallback(
        (e, catalog) => {
            if(e.key === 'Enter' || e.key === 'Space'){
                cardClickHandler(e, catalog)
            }
        },
        [cardClickHandler],
    )

    const Cards = useCallback(
        () => <>
                { isUnderclassmenEssentials &&
                    <m.div
                        className="item"
                        variants={product}
                    >
                        <div className="img-wrapper">
                            <div className="bg"></div>
                            <img src={domainConverter(underclassmenEssentialsData?.imageUrl)} alt={underclassmenEssentialsData?.imageAltText} loading="lazy" />
                        </div>
                        <div className="content">
                            <h4>{underclassmenEssentialsData.title}</h4>
                            <div className="text" dangerouslySetInnerHTML={{__html: underclassmenEssentialsData.content}}></div>
                            <div><a href="/products/high-school" target="_blank" className="btn btn-primary btn-primary--ghost" rel="noopener noreferrer">Shop Now</a></div>
                        </div>
                    </m.div>
                }
                { isTryOn &&
                    <m.div
                        className="item"
                        variants={product}
                    >
                        <div className="img-wrapper">
                            <div className="bg"></div>
                            <img src={domainConverter(tryOnData?.imageUrl)} alt={tryOnData?.imageAltText} loading="lazy" />
                        </div>
                        <div className="content">
                            <h4>{tryOnData.title}</h4>
                            <div className="text" dangerouslySetInnerHTML={{__html: tryOnData.content}}></div>
                            <div><a href={storedata.schoolStoreTryOn.tryOnBannerUrl} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-primary--ghost">Try Now</a></div>
                        </div>
                    </m.div>
                }
            </>,
        [isUnderclassmenEssentials, isTryOn, product, storedata, underclassmenEssentialsData, tryOnData],
    )

    const Cats = useCallback(
        () => {
            if(catalogsQty === 1){
                return <div className="carousel">
                        {storedata.productCatalogs.map((catalog, key) =>
                            <m.div
                                className="item"
                                style={{cursor: 'pointer'}}
                                onClick={(e)=>{cardClickHandler(e, catalog)}}
                                onKeyPress={(e)=>{cardKeyPressHandler(e, catalog)}}
                                key={`CatalogItem-${key}`}
                                variants={product}
                                tabIndex='0'
                            >
                                <div className="img">
                                    <img loading="lazy" src={imgHandler(catalog.catalogOsr) ? domainConverter(imgHandler(catalog.catalogOsr)) : domainConverter(catalog.imageUrl)} alt={catalog.displayName} />
                                </div>
                                <div className="overlay">
                                    <div className="inner">
                                        <h3>{catalog.displayName}</h3>
                                        {window.innerWidth > 576 && <div className="btn btn-sm btn-white btn-white--ghost">Shop</div>}
                                    </div>
                                </div>
                            </m.div>
                        )}
                    </div>
            } else {
                return <Slider {...settings} className="carousel">
                        {storedata.productCatalogs.map((catalog, key) =>
                            <m.div
                                className="item"
                                style={{cursor: 'pointer'}}
                                onClick={(e)=>{cardClickHandler(e, catalog)}}
                                onKeyPress={(e)=>{cardKeyPressHandler(e, catalog)}}
                                key={`CatalogItem-${key}`}
                                variants={product}
                            >
                                <div className="img">
                                    <img loading="lazy" src={imgHandler(catalog.catalogOsr) ? domainConverter(imgHandler(catalog.catalogOsr)) : domainConverter(catalog.imageUrl)} alt={catalog.displayName} />
                                </div>
                                { window.innerWidth > 576
                                    ?
                                    <div className="overlay">
                                        <div className="inner">
                                            <h3>{catalog.displayName}</h3>
                                            <div className="btn btn-sm btn-white btn-white--ghost">Shop</div>
                                        </div>
                                    </div>
                                    :
                                    <div className="substring">{catalog.displayName}</div>
                                }
                            </m.div>
                        )}
                    </Slider>
            }
        }, [storedata.productCatalogs, cardClickHandler, cardKeyPressHandler, catalogsQty, imgHandler, product, settings]
    )
	return (
        <LazyMotion features={domAnimation}>
            <div
                className={'container combo' + itemsClassName}
                // ref={ref}
            >
                <m.div
                    // ref={slickRef}
                    className="container"
                    variants={container}
                    initial="hidden"
                    animate="show"
                >
                    { mascotName && storedata ? <h2>SHOW YOUR {mascotName} PRIDE</h2> : storedata ? <h2>SHOW YOUR PRIDE</h2> : null}
                        <div className='row'>
                            { comboCase === 11 &&
                                <>
                                    <div className="col-xs-12 col-xl-7 product-categories-col">
                                        <div className="product-categories items-1">
                                            <Cats />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-xl-5 card-section-col">
                                        <div className="card-section items-1" style={{overflow: "inherit"}}>
                                            <Cards />
                                        </div>
                                    </div>
                                </>
                            }
                            { comboCase === 12 &&
                                <>
                                    <div className="col-xs-12 col-xl-6 product-categories-col">
                                        <div className="product-categories items-1">
                                            <Cats />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-xl-6 card-section-col">
                                        <div className="card-section items-2">
                                            <Cards />
                                        </div>
                                    </div>
                                </>
                            }
                            { comboCase === 21 &&
                                <>
                                    <div className="col-xs-12 col-xl-7 col-xxl-8 product-categories-col">
                                        <div className="product-categories items-2">
                                            <div className="container" style={{padding:0}}>
                                                <Cats />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-xl-5 col-xxl-4 card-section-col">
                                        <div className="card-section items-1"  style={{overflow: "inherit"}}>
                                            <Cards />
                                        </div>
                                    </div>
                                </>
                            }
                            { comboCase === 22 &&
                                <>
                                    <div className="col-xs-12 col-xl-6 product-categories-col" style={{padding:0}}>
                                        <div className="product-categories items-2">
                                            <div className="container" style={{padding:0}}>
                                                <Cats />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-xl-6 card-section-col">
                                        <div className="card-section items-2">
                                            <Cards />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                </m.div>
            </div>
        </LazyMotion>
	)
}

export default Combo