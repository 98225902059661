import React, { useContext, useCallback } from 'react'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { MainContext } from "../../context/mainContext";
import { actionUrlConverter, alternateUrlConverter } from '../../util/util'

export const HeroCatalogCounter = ({ catalog, days, hours }) => {
    const ctx = useContext(MainContext)

    const cardClickHandler = useCallback(
        (e, catalog) => {
            e.stopPropagation()
            if (catalog.subCatalogs?.length) {
                ctx.openPopup({
                    class: 'small',
                    title: 'Select Your Collection',
                    content:<div className="list-group">
                                {
                                    catalog.subCatalogs.map( cat => <a key={cat.id} className="list-group-item" href={actionUrlConverter(cat.actionUrl)}><span>{cat.displayName}</span></a> )
                                }
                            </div>
                })
            } else {
                catalog.alternateUrl ? window.open(alternateUrlConverter(catalog.alternateUrl), '_blank', 'noopener,noreferrer') : window.open(actionUrlConverter(catalog.actionUrl), '_self')
            }
        },
        [],
    )

    return (
        <LazyMotion features={domAnimation}>
            <m.div
                className="event"
                animate={{
                    y: [30, 0],
                    opacity: [0, 1],
                    transition: {
                        delay: 1.5,
                        duration: .5,
                    },
                }}
            >
                <div className="date-info">
                    {/* <div>{days}<span>days </span></div> */}
                    { days > 0 ? <div>{days}<span>DAYS REMAINING</span></div> : <div>LAST DAY</div>}
                </div>
                <div className="detail">
                    <div className="hdr">HURRY, ACT FAST!</div>
                    <h5>SPECIAL YEARBOOK OFFER</h5>
                    <div className="date">DON'T MISS OUT</div>
                </div>
                <div className="controls">
                    <a href='#' onClick={(e)=>{cardClickHandler(e, catalog)}} className="btn btn-primary btn-primary--ghost">BUY NOW</a>
                </div>
            </m.div>
        </LazyMotion>
    )
}
