import React, { useContext, useCallback } from 'react'
import { m, LazyMotion, domAnimation } from "framer-motion"
import { MainContext } from "../../context/mainContext";

export const EventCalendar = ({ eventData, daysToStart, hoursToStart }) => {
    const ctx = useContext(MainContext)

    const bookAppointmentHandler = useCallback(
        () => {
            ctx.openPopup({
                class: 'small',
                title: 'Book Appointment',
                content:    <iframe id="bookAppointmentIFrame"
                                title="Book Appointment"
                                width="100%"
                                height="700px"
                                frameBorder="0"
                                src={eventData.schedulingUrl}>
                            </iframe>
            })
        },
        [eventData],
    )

    return (
        <LazyMotion features={domAnimation}>
            <m.div
                className="event"
                animate={{
                    y: [30, 0],
                    opacity: [0, 1],
                    transition: {
                        delay: 1.5,
                        duration: .5,
                    },
                }}
            >
                <div className="date-info">
                    { daysToStart >= 0 && hoursToStart >= 0 && <div>{daysToStart}<span>days</span></div> }
                    { daysToStart >= 0 && hoursToStart >= 0 && <div>{hoursToStart}<span>hours</span></div> }
                </div>
                <div className="detail">
                    <div className="hdr">{ daysToStart >= 0 && hoursToStart > 0 ? 'Upcoming Event' : 'Happening Today'}</div>
                    <h5>{eventData.name}</h5>
                    <div className="date">{eventData.dateRange.split(' - ')[1] === eventData.dateRange.split(' - ')[0] ? eventData.dateRange.split(' - ')[0].replace(/,/g, ', ') : eventData.dateRange.replace(/,/g, ', ')}</div>
                </div>
                <div className="controls">
                    {eventData.schedulingUrl && <a href='#' onClick={bookAppointmentHandler} className="btn btn-primary btn-primary--ghost">Book Appointment</a>}
                </div>
            </m.div>
        </LazyMotion>
    )
}
