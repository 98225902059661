import React, { useCallback, useEffect, useContext, useState } from 'react';
import { createRoot } from 'react-dom/client';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { MainContext } from '../../context/mainContext';
import { gaPageLoad } from "../../util/googleAnalytics";
import CatalogList from "../home/catalogList";
import OlapicList from "../home/olapicList";
import { Breadcrumb } from "./breadcrumb";
import { renderPromoMessages } from '../components/promoMessages';

export const GradProductsPage = ({ appData}) => {
	const { storedata, customerdata } = appData
	const [catalogData, setCatalogData] = useState();
	const [promoMessages, setPromoMessages] = useState([]);
	const ctx = useContext(MainContext);

	const catalogsQty = storedata?.productCatalogs?.length;
	const isBreadCrumbs = Boolean(
		customerdata?.find((data) => data.contentType === 'Navigation')
	);
	const isOlapic = Boolean(
		customerdata?.find((data) => data.contentType === 'OlapicWidget')
	);
	const isGradProductsPromoMsg = Boolean(
		customerdata?.find(
			(data) => data.contentType === 'ProductBrowsePromoMsg'
		)
	);

	const linkOnClickHandler = useCallback(() => {
		ctx.setLoading(true);
	}, []);

	useEffect(() => {
		const setSitemap = setTimeout(() => {
			forceCheck();
			const cLinks = document.querySelectorAll('.footer-link>a');
			const siteMap = document.querySelector('a[href="/site-map"]');
			siteMap && siteMap.setAttribute('href', '/apps/store/sitemap/-/-/');
			cLinks.forEach((link) => {
				link.addEventListener('click', linkOnClickHandler, false);
			});
		}, 1500);
		return () => {
			clearTimeout(setSitemap);
		};
  }, []);
	
	useEffect(()=>{
		gaPageLoad();
	},[]);

	useEffect(()=> {
		let isCalatlog = false;
		storedata?.productCatalogs?.forEach((catalog)=>{
			if(catalog.catalogOsr == "col_grad" ){
				isCalatlog = true
				setCatalogData(catalog);
			} 
		});
		setPromoMessages(storedata?.schoolStorePromoMessagesV2 || [])
		if(!isCalatlog ){
			window.location.href = "/apps/jcom/router.mvc?error=true&errorGroupName=GraduationProductsCatalog-missing";
		}
	},[appData])
	
	
	const getBreadcrumb = useCallback(() => {
		document.getElementById('breadcrumbs') &&
			createRoot(document.getElementById('breadcrumbs')).render(
				<Breadcrumb pbData={storedata} />
			);
	}, [storedata]);

	const getPromoMsg = useCallback(() => {
		renderPromoMessages(promoMessages)
	}, [promoMessages]);
  
	const getCatalogTiles = useCallback(() => {
		document.getElementById('productCatalog') &&
			createRoot(document.getElementById('productCatalog')).render(
				<MainContext.Provider value={ctx}>
					<LazyLoad offset={100} height={500} once resize>
						<CatalogList appData={appData} catalogData={catalogData}/>
					</LazyLoad>
				</MainContext.Provider>
			);
	}, [appData, catalogData, ctx]);
	
	const getOlapicSection = useCallback(() => {
		document.getElementById('olapicSection') &&
			createRoot(document.getElementById('olapicSection')).render(
				<MainContext.Provider value={ctx}>
					<LazyLoad offset={100} height={500} once>
						<OlapicList appData={appData} />
					</LazyLoad>
				</MainContext.Provider>
			);
  }, [appData, ctx]);
  
	return (
		<>
			{isBreadCrumbs && getBreadcrumb()}
			{isGradProductsPromoMsg && (promoMessages?.length > 0) && getPromoMsg()}
			{catalogsQty && catalogData && getCatalogTiles()}
			{isOlapic && getOlapicSection()}
		</>
	);
};
