import React from 'react'
import { domainConverter, actionUrlConverter } from '../../util/util'

export const SingleBanner = React.memo(({ appData:{customerdata} }) => {

	const customerBannerSectionData = customerdata?.filter(data => data.contentType === "BannerSection")[0]

	if (customerBannerSectionData) {
		return (
			<div className="banner-section">
				<div className="item">
					<div className="img-wrapper">
						<picture>
							<source media="(min-width: 1200px)" srcSet={domainConverter(customerBannerSectionData.imageUrl.xl)} />
							<source media="(min-width: 1024px)" srcSet={domainConverter(customerBannerSectionData.imageUrl.lg)} />
							<source media="(min-width: 768px)" srcSet={domainConverter(customerBannerSectionData.imageUrl.md)} />
							<source media="(min-width: 576px)" srcSet={domainConverter(customerBannerSectionData.imageUrl.sm)} />
							<img src={domainConverter(customerBannerSectionData.imageUrl.xl)} alt={customerBannerSectionData.imageAltText} />
						</picture>
					</div>
					<div className="overlay">
						{ customerBannerSectionData.title && <h2>{customerBannerSectionData.title}</h2>}
						{ customerBannerSectionData.content && <div className="content" dangerouslySetInnerHTML={{__html: customerBannerSectionData.content}}></div>}
						{ customerBannerSectionData.button && <div><a href={actionUrlConverter(customerBannerSectionData.button.link)} target={customerBannerSectionData.button.target} className="btn btn-white btn-white--ghost">{customerBannerSectionData.button.text}</a></div>}
					</div>
				</div>
			</div>
		)
	} else {
		return null
	}
})

// export default React.memo(SingleBanner)